import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import {
    Form,
    Button,
    Checkbox,
    Header,
    List,
    Label
} from 'semantic-ui-react';

import {
    patientService,
    alertService,
    facilityService,
    customerService,
    userService
} from '../_services';
import {
    Countries,
    States,
    booleanOptions,
    genderOptions,
    sexOptions,
    iphoneOptions,
    iosOptions,
    lowerPatchBackSettingOptions,
    lowerPatchFrontSettingOptions,
    upperPatchPadSettingOptions,
    frontShoulderStrapSettingOptions,
    backShoulderStrapSettingOptions,
    days,
} from '../models/Constants';
import { Link } from 'react-router-dom';
import { verifyAddress } from '../_helpers/verify-address';
import { FormControlLabel, Switch } from '@material-ui/core';

function AddEdit({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const pathname = window.location.pathname;
    const [isLoading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState(null);
    const [providerMap, setProviderMap] = useState({});
    const [sites, setSites] = useState([]);
    const [disableProvider, setDisableProvider] = useState(false);
    const [disabledProviders,setDisableProviders] = useState({});
    const [isChecked, handleCheck] = useState(false);
    const [previousDevices, setPreviousDevices] = useState(null);
    const [dateOfLastTherapy, setDateOfLastTherapy] = useState("");
    const [expectedExitDate, setExpectedExitDate] = useState(false);
    const [prescriptionLen, setPrescriptionLen] = useState("");
    const [preferredPhone, setPreferredPhone] = useState("mobile");
    const [usingMobilePhone, setUsingMobilePhone] = useState(false);
    //const [clinicalSiteManagers, setClinicalSiteManagers] = useState([]);

    // View mode
    const [viewMode, setViewMode] = useState(false);
    // form validation rules
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('First Name is required'),
        last_name: Yup.string()
            .required('Last Name is required'),
        external_user_id: Yup.string()
            .matches(/^[a-zA-Z]{2}-[a-zA-Z]{3}-\d{4}$/, 'Patient ID must be in the format SS-HHH-XXXX')
            .required('External User ID is required'),
        gender: Yup.string()
            .required('Gender is required'),
        enrolled_date: Yup.string()
            .matches(/^\d{2}-\d{2}-\d{4}$/, 'Enrollment date must be in the format MM-DD-YYYY')
            .required('Enrolled date is required in the format MM-DD-YYYY'),
        prescription_length: Yup.number()
            .required('Prescription Length is required'),
        unenrolled_at: Yup.string()
            .matches(/^\d{2}-\d{2}-\d{4}$/, 'Exit date must be in the format MM-DD-YYYY'),
        date_of_birth: Yup.string()
            .matches(/^\d{2}-\d{2}-\d{4}$/, 'DOB must be in the format MM-DD-YYYY')
            .required('Date of Birth is required in the format MM-DD-YYYY'),
        indication: Yup.string()
            .required('Indication is required')
            .max(40, 'Indication may not exceed 40 characters'),
        permanent_address: Yup.object().shape({
            street_address_1: Yup.string().required('Street Address 1 is required'),
            street_address_2: Yup.string(),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            postal_code: Yup.string().required('Postal Code is required'),
            country: Yup.string().required("Country is required"),
        }),
        mobile_phone_number: Yup.string()
            .max(12, 'Phone number must be no more than 12 digits long')
            .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx')
            .required('Phone Number is required'),
        landline_phone_number: Yup.string()
            .max(12, 'Phone number must be no more than 12 digits long')
            .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        preferred_phone: Yup.string().required(),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        is_mobile_app_user: Yup.bool().nullable(),
        mobile_model: Yup.string()
            .when('is_mobile_app_user', {
            is: true,
            then: Yup.string().required("Mobile model required")}),
        mobile_os_version: Yup.string()
            .when('is_mobile_app_user', {
            is: true,
            then: Yup.string().required("Mobile version required")}),
        emergency_contact_name: Yup.string(),
        emergency_contact_relationship: Yup.string(),
        emergency_contact_number: Yup.string()
            .max(12, 'Phone number must be no more than 12 digits long')
            .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        prescribing_provider: Yup.string()
            .when("facility_name", {
            is: (value) => disableProvider === false,
            then: Yup.string().required('Prescribing Provider ID is required'),
            otherwise: Yup.string()
        }),
        prescribing_provider_number: Yup.string(),
        prescribing_provider_email: Yup.string(),
        facility_name: Yup.string()
            .required('Site name required'),
        site_manager: Yup.string()
            .required('FCR name is required'),
        site_investigator_name: Yup.string(),
        study_coordinator_name: Yup.string(),
        outpatient_provider: Yup.string(),
        device_serial_number: Yup.string()
            .min(8, 'Must be exactly 8 digits')
            .max(8, 'Must be exactly 8 digits'),
        starter_kit_lot_number: Yup.string()
            .min(9, 'Must be exactly 9 digits')
            .max(9, 'Must be exactly 9 digits'),
        applied_patch_lot_number: Yup.string()
            .min(9, 'Must be exactly 9 digits')
            .max(9, 'Must be exactly 9 digits'),
        unused_patch_lot_number: Yup.string()
            .min(9, 'Must be exactly 9 digits')
            .max(9, 'Must be exactly 9 digits'),
        pa_setting_front: Yup.string(),
        pa_setting_back: Yup.string(),
        upper_patch_setting: Yup.string(),
        shoulder_strap_front: Yup.string(),
        shoulder_strap_back: Yup.string(),
        enrollment_notes: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            external_user_id: "",
            gender: "",
            date_of_birth: "",
            indication: "",
            permanent_address: {
                street_address_1: "",
                street_address_2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
            },
            mobile_phone_number: "",
            landline_phone_number: "",
            email: "",
            emergency_contact_name: "",
            emergency_contact_relationship: "",
            emergency_contact_number: "",
            is_mobile_app_user: "",
            mobile_model: "",
            mobile_os_version: "",
            facility_id: "",
            facility_name: "",
            site_manager: "",
            site_investigator_name: "",
            study_coordinator_name: "",
            outpatient_provider: "",
            prescribing_provider: "",
            prescribing_provider_number: "",
            prescribing_provider_email: "",
            device_serial_number: "",
            starter_kit_lot_number: "",
            applied_patch_lot_number: "",
            unused_patch_lot_number: "",
            pa_setting_front: "",
            pa_setting_back: "",
            upper_patch_setting: "",
            shoulder_strap_front: "",
            shoulder_strap_back: "",
            enrollment_notes: "",
            enrolled_date: "",
            prescription_length: "",
            preferred_phone: "mobile"
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    })

    // functions to build form returned by useForm() hook
    const { setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    async function onSubmit(data) {
        //Set preferred phone
        data["preferred_phone"] = preferredPhone
        // Delete unecessary fields - find better way to exclude fields in final JSON
        delete data["facility_name"];
        delete data["prescribing_provider_email"];
        delete data["prescribing_provider_number"];
        delete data["study_coordinator_name"];
        delete data["site_investigator_name"];
        delete data["estimated_exit"];
        // Strip dashes from numbers
        if (data["landline_phone_number"]) {
            data["landline_phone_number"] = data["landline_phone_number"].replace(/-/g, "");
        }
        data["mobile_phone_number"] = data["mobile_phone_number"].replace(/-/g, "");
        data["emergency_contact_number"] = data["emergency_contact_number"].replace(/-/g, "");


        if (data["enrolled_date"] == "" || data["enrolled_date"] == null || data["enrolled_date"] == undefined) {
            delete data["enrolled_date"];
        } else {
            data["enrolled_date"] = new Date(data["enrolled_date"]).toISOString();
        }

        if (data["unenrolled_at"] == "" || data["unenrolled_at"] == null || data["unenrolled_at"] == undefined) {
            delete data["unenrolled_at"];
        } else {
            data["unenrolled_at"] = new Date(data["unenrolled_at"]).toISOString();
        }

        if (data["prescription_length"] == "" || data["prescription_length"] == null || data["prescription_length"] == undefined) {
            delete data["prescription_length"];
        }

        let verified_address = await verifyAddress(data['permanent_address'])
        if (verified_address != null){
            data['permanent_address'] = verified_address;
        }else{
            formik.setSubmitting(false);
            return alertService.error('Address is not valid. Please enter a valid address.');
        }

        return isAddMode
            ? createPatient(data)
            : updatePatient(id, data);
    }

    function createPatient(data) {
        return patientService.create(data)
            .then((respdata) => {
                alertService.success('Patient added', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function updatePatient(id, data) {

        return patientService.update(id, data)
            .then(() => {
                alertService.success('Patient updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    const handleCheckbox = (data) => {
       if (data.checked) {
           // disable prescribing provider fields
           setDisableProvider(true);
           handleCheck(true);
           formik.setFieldValue("prescribing_provider", formik.values.outpatient_provider);
       } else {
           setDisableProvider(false);
           handleCheck(false);
       }
    };

    const handleOptionChange = (option, field) => {
        formik.setFieldValue(field, option);
    }

    const populateProvidersOptions = (provs) => {
        const providersList = [];
        provs.map(x => {
            providersList.push(
                {
                    id: x.id,
                    value: x.id,
                    text: x.name,
                    number: x.phone,
                    email: x.email,
                    status: x.status
                }
            )
        })
        setProviderList(providersList);

        const providersMap = {};
        provs.map(x => {
            providersMap[x.id] = {
                id: x.id,
                value: x.id,
                text: x.name,
                number: x.phone,
                email: x.email,
                status:x.status
            }
        });
        const disabledProvs = {}
        provs.map(x =>{
            disabledProvs[x.id] = x.status;
        });
        setDisableProviders(disabledProvs);
        setProviderMap(providersMap);
    }

    const estimateExitDate = () => {
        // const date = moment(formik.values.enrolled_date).format("MM-DD-YYYY").toDate();
        var date = new Date(formik.values.enrolled_date);

        date.setDate(date.getDate() + parseInt(formik.values.prescription_length));

        let  month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let year = date.getFullYear();
        let readable_date = month + '-' + day + '-' + year;
        formik.setFieldValue("estimated_exit", readable_date);
        setExpectedExitDate(readable_date);
    }

    // Set the site information as well as the outpatient provider
    const handleSiteChange = (data) => {
        if (data.value && data.options) {
            const siteOption = data.options.find(function(site){
                return site.id === data.value
                });

            formik.setFieldValue(data.name, data.value);
            formik.setFieldValue("facility_id",data.value);
            formik.setFieldValue("site_investigator_name", siteOption.si_name ? siteOption.si_name : "N/A");
            formik.setFieldValue("study_coordinator_name", siteOption.sc_name ? siteOption.sc_name : "N/A");

            // Search for outpatient provider to set
            for (var i = 0; i < data.options.length; i++) {
                if (data.options[i].value === data.value) {

                    formik.setFieldValue("outpatient_provider", data.options[i].outpatient_provider);
                }
            }

            for (var i = 0; i < data.options.length; i++) {
                if (data.options[i].value === data.value) {

                    formik.setFieldValue("site_manager", data.options[i].site_managers);
                }
            }

            const provs = siteOption.providers;
            populateProvidersOptions(provs);
        }
    }

    // Sets prescribing provider
    const handleProviderChange = (data) => {
        if (data.value) {
            formik.setFieldValue(data.name, data.value);
            formik.setFieldValue("prescribing_provider_number", providerMap[data.value].number ? providerMap[data.value].number : "N/A");
            formik.setFieldValue("prescribing_provider_email", providerMap[data.value].email ? providerMap[data.value].email : "N/A");
        }
    }

    function handleResendLoginInfo() {
        const patient_email = formik.values.email;
        return customerService.resendLoginInfo({ user_email: patient_email }).then((response) => {
            alertService.success('Login Info resent', { keepAfterRouteChange: true });
            history.push('..');
        })
        .catch((err) => {
            alertService.error(err);
        });
    }

    useEffect(() => {
        if (isAddMode) {
            // Set USA as default country
            formik.setFieldValue("permanent_address.country", "USA");

            facilityService.getSitesProviders()
            .then((_sites) => {
                if(_sites) {
                    // Form - Set Sites and Providers options
                    const siteOptions = [];
                    _sites.data.map(x => {
                        siteOptions.push(
                            {
                                id: x.site_id,
                                text: x.site_name + ' - ' + x.external_id,
                                value: x.site_id,
                                si_name: x.principal_investigator.name,
                                sc_name: x.study_coordinator,
                                outpatient_provider: x.principal_investigator.id,
                                providers: x.prescribing_providers,
                                external_id: x.external_id,
                                site_managers: x.primary_contact_id,
                            }
                        );
                    });
                    setSites(siteOptions);
                }
            })
            .catch((err) => {
                console.log("No sites found!");
            });
        }
    }, []);

    useEffect(() => {
        if (pathname.includes("view")) {
            setViewMode(true);
        } else {
            setViewMode(false);
        }
    }, [pathname]);

    // Loading wheel for edit/view
    useEffect(() => {
        if (window.onload || isAddMode) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [window]);

    useEffect(() => {
        // loadClinicalSiteManagers();

        if (!isAddMode) {
            patientService.getById(id)
            .then(patient => {
                setPreferredPhone(patient.patient.patient.preferred_phone);
                setLoading(false);
                const currentPatient = patient.patient.patient;

                // get patient and set form fields
                facilityService.getSitesProviders()
                .then((_sites) => {
                    if(_sites) {
                        // Form - Set Sites and Providers options
                        const siteOptions = [];
                        _sites.data.map(x => {
                            siteOptions.push(
                                {
                                    id: x.site_id,
                                    text: x.site_name,
                                    value: x.site_id,
                                    si_name: x.principal_investigator.name,
                                    sc_name: x.study_coordinator,
                                    outpatient_provider: x.principal_investigator.id,
                                    providers: x.prescribing_providers
                                }
                            );
                        });
                        setSites(siteOptions);

                        const currentPatientOutpatientProvider = patient.patient.outpatient_provider;
                        const currentPatientPrescribingProvider = patient.patient.prescribing_provider;

                        for (var index = 0; index < _sites.data.length; index++) {
                            const _facility = _sites.data.find(function(site){
                                return site.site_id === patient.patient.facility_id
                            });

                            // Check if site found via principal investigator id
                            if (_facility.principal_investigator.id === currentPatientOutpatientProvider) {
                                // Populate dropdowns
                                
                                formik.setFieldValue("facility_id", _facility.site_id);
                                formik.setFieldValue("facility_name", _facility.site_id);

                               
                                //formik.setFieldValue("site_manager", clinicalSiteManagers[_facility.primary_contact_id]);

                                formik.setFieldValue("site_manager", _facility.primary_contact_id);
                                populateProvidersOptions(_facility.prescribing_providers);

                                // Populate Site information
                                formik.setFieldValue("site_investigator_name", _facility.principal_investigator.name);
                                formik.setFieldValue("study_coordinator_name", _facility.study_coordinator);

                                // Check to see if outpatient and prescribing providers are the same
                                if (currentPatientOutpatientProvider === currentPatientPrescribingProvider) {
                                    setDisableProvider(true);
                                    handleCheck(true);
                                    formik.setFieldValue("prescribing_provider", currentPatientOutpatientProvider);
                                    formik.setFieldValue("outpatient_provider", currentPatientOutpatientProvider);

                                    // Set these to blank, since prescribing provider is the outpatient/site_investigator
                                    formik.setFieldValue("prescribing_provider_number", " ");
                                    formik.setFieldValue("prescribing_provider_email", " ");
                                } else {
                                    setDisableProvider(false);
                                    handleCheck(false);

                                    formik.setFieldValue("outpatient_provider", currentPatientOutpatientProvider);

                                    for(const item in _facility.prescribing_providers) {
                                        let provider = _facility.prescribing_providers[item];
                                        if (provider.id === patient.patient.prescribing_provider) {
                                            formik.setFieldValue("prescribing_provider", provider.id);
                                            formik.setFieldValue("prescribing_provider_number", provider.phone);
                                            formik.setFieldValue("prescribing_provider_email", provider.email);
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log("error on facilities_providers: ", err);
                })
                setDateOfLastTherapy(currentPatient.last_therapy)
                formik.setFieldValue("first_name", currentPatient.user.first_name);
                formik.setFieldValue("last_name", currentPatient.user.last_name);
                formik.setFieldValue("external_user_id", currentPatient.user.external_user_id);
                formik.setFieldValue("gender", currentPatient.gender);
                var enrolled_readable_date = new Date(currentPatient.enrolled_date);
                let month = (enrolled_readable_date.getMonth() + 1).toString().padStart(2, '0');
                let day = enrolled_readable_date.getDate().toString().padStart(2, '0');
                let year = enrolled_readable_date.getFullYear();
                enrolled_readable_date = month + '-' + day + '-' + year;
                formik.setFieldValue("enrolled_date", enrolled_readable_date);

                if (currentPatient.unenrolled_at) {
                    var readable_date = new Date(currentPatient.unenrolled_at);
                    month = (readable_date.getMonth() + 1).toString().padStart(2, '0');
                    day = readable_date.getDate().toString().padStart(2, '0');
                    year = readable_date.getFullYear();
                    readable_date = month + '-' + day + '-' + year;
                    formik.setFieldValue("unenrolled_at", readable_date);
                } else {
                    formik.setFieldValue("unenrolled_at", '');
                }
                formik.setFieldValue("prescription_length", currentPatient.prescription_length);

                var date = new Date(enrolled_readable_date);

                date.setDate(date.getDate() + parseInt(currentPatient.prescription_length));

                month = (date.getMonth() + 1).toString().padStart(2, '0');
                day = date.getDate().toString().padStart(2, '0');
                year = date.getFullYear();
                readable_date = month + '-' + day + '-' + year;
                setExpectedExitDate(readable_date);
                formik.setFieldValue("estimated_exit", readable_date);

                formik.setFieldValue("date_of_birth", currentPatient.date_of_birth);
                formik.setFieldValue("indication", currentPatient.indication);

                formik.setFieldValue("permanent_address.street_address_1", currentPatient.permanent_address.street_address_1);
                formik.setFieldValue("permanent_address.street_address_2", currentPatient.permanent_address.street_address_2);
                formik.setFieldValue("permanent_address.city", currentPatient.permanent_address.city);
                formik.setFieldValue("permanent_address.state", currentPatient.permanent_address.state);
                formik.setFieldValue("permanent_address.postal_code", currentPatient.permanent_address.postal_code);
                formik.setFieldValue("permanent_address.country", currentPatient.permanent_address.country);
                currentPatient.mobile_phone_number = currentPatient.mobile_phone_number.slice(0,3)+"-"+currentPatient.mobile_phone_number.slice(3,6)+"-"+currentPatient.mobile_phone_number.slice(6);
                if(patient.patient.landline_phone_number){
                    patient.patient.landline_phone_number = patient.patient.landline_phone_number.slice(0,3)+"-"+patient.patient.landline_phone_number.slice(3,6)+"-"+patient.patient.landline_phone_number.slice(6);
                }
                formik.setFieldValue("mobile_phone_number", currentPatient.mobile_phone_number);
                formik.setFieldValue("landline_phone_number", patient.patient.landline_phone_number);
                formik.setFieldValue("email", currentPatient.user.registration.email);
                formik.setFieldValue("emergency_contact_name", currentPatient.emergency_contact_name);
                if(currentPatient.emergency_contact_number.length > 0){
                    currentPatient.emergency_contact_number = currentPatient.emergency_contact_number.slice(0,3)+"-"+currentPatient.emergency_contact_number.slice(3,6)+"-"+currentPatient.emergency_contact_number.slice(6);
                }
                formik.setFieldValue("emergency_contact_number", currentPatient.emergency_contact_number);
                formik.setFieldValue("emergency_contact_email", currentPatient.emergency_contact_email);
                formik.setFieldValue("emergency_contact_relationship", currentPatient.emergency_contact_relationship);

                setUsingMobilePhone(patient.patient.details.is_mobile_app_user);
                formik.setFieldValue("is_mobile_app_user", patient.patient.details.is_mobile_app_user);
                formik.setFieldValue("mobile_model", patient.patient.details.mobile_model);
                formik.setFieldValue("mobile_os_version", patient.patient.details.mobile_os_version);

                // Get active and most recent device serial number from list
                if (currentPatient.devices) {
                    let previousDevices = []
                    for (var i = 0; i < currentPatient.devices.length; i++) {
                        if (currentPatient.devices[i] && (currentPatient.devices[i].is_active === true)) {
                            formik.setFieldValue("device_serial_number", currentPatient.devices[i].device_serial_number ? currentPatient.devices[i].device_serial_number : "N/A");
                        }
                        else {
                            previousDevices.push(currentPatient.devices[i].device_serial_number)
                        }
                    }
                    setPreviousDevices(previousDevices);
                }
                formik.setFieldValue("starter_kit_lot_number", patient.patient.details.starter_kit_lot_number);

                if (currentPatient.patches[0] && currentPatient.patches[1]) {
                    formik.setFieldValue("applied_patch_lot_number", currentPatient.patches[0].is_applied ? currentPatient.patches[0].patch_lot_number : currentPatient.patches[1].patch_lot_number);
                    formik.setFieldValue("unused_patch_lot_number", !currentPatient.patches[0].is_applied ? currentPatient.patches[0].patch_lot_number : currentPatient.patches[1].patch_lot_number);
                } else {
                    formik.setFieldValue("applied_patch_lot_number", "");
                    formik.setFieldValue("unused_patch_lot_number", "");
                }

                formik.setFieldValue("pa_setting_front", patient.patient.details.pa_setting_front);
                formik.setFieldValue("pa_setting_back", patient.patient.details.pa_setting_back);
                formik.setFieldValue("upper_patch_setting", patient.patient.details.upper_patch_setting);
                formik.setFieldValue("shoulder_strap_front", patient.patient.details.shoulder_strap_front);
                formik.setFieldValue("shoulder_strap_back", patient.patient.details.shoulder_strap_back);

                formik.setFieldValue("enrollment_notes", patient.patient.details.enrollment_notes);
                })
                .catch( err => {
                    console.log("Unable to get patient information, error: ", err);
                });
            }
    }, []);

    /*
    const loadClinicalSiteManagers = () => {
        userService.getUserByRole("ES_CLINICAL_SITE_MANAGER")
        .then((_clinicalSiteManagers) => {
            if(_clinicalSiteManagers) {
                // Set Clinical Site Manager options
                const clinicalSiteManagerOptions = [];
                _clinicalSiteManagers.data[0].map(x => {
                    clinicalSiteManagerOptions.push(
                        {
                            key: x.user_id,
                            text: x.first_name + " " + x.last_name,
                            value: x.user_id
                        }
                    );
                });
                setClinicalSiteManagers(clinicalSiteManagerOptions);
            }
        })
        .catch((err) => {
            console.log("No Clinical Site Managers found!");
        });
    }
    */

    return (
        <Form className="pl-5" loading={isLoading} onSubmit={formik.handleSubmit}>
            <div className="mt-4">
            <Header as='h2' style={{ color:'#3F2A57'}} dividing>Patient Details</Header>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="First name"
                        placeholder="First Name"
                        name="first_name"
                        width={2}
                        onChange={formik.handleChange}
                        error={formik.errors.first_name}
                        value={formik.values.first_name}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Last name"
                        placeholder="Last Name"
                        name="last_name"
                        width={2}
                        onChange={formik.handleChange}
                        error={formik.errors.last_name}
                        value={formik.values.last_name}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Patient ID Number"
                        placeholder="Site ID - Patient ID (xxxx-xxxx)"
                        name="external_user_id"
                        width={2}
                        onChange={formik.handleChange}
                        error={formik.errors.external_user_id}
                        value={formik.values.external_user_id}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Sex"
                        placeholder="Sex"
                        options={sexOptions}
                        name="gender"
                        width={2}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.gender}
                        value={formik.values.gender}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Date of Birth"
                        placeholder="Date of Birth"
                        name="date_of_birth"
                        width={2}
                        onChange={formik.handleChange}
                        onBlur={() => {
                            const newDate = moment(formik.values.date_of_birth).format("MM-DD-YYYY");
                            formik.setFieldValue("date_of_birth", newDate);
                        }}
                        error={formik.errors.date_of_birth}
                        value={formik.values.date_of_birth}
                    />
                </Form.Group>
                <Form.Group>
                <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Enrolled Date"
                        placeholder="Enrolled Date"
                        name="enrolled_date"
                        width={2}
                        onChange={formik.handleChange}
                        onBlur={() => {
                            const newDate = moment(formik.values.enrolled_date).format("MM-DD-YYYY");
                            formik.setFieldValue("enrolled_date", newDate);
                        }}
                        value={formik.values.enrolled_date}
                        error={formik.errors.enrolled_date}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Prescription Length"
                        placeholder="Prescription Length"
                        name="prescription_length"
                        width={2}
                        onChange={formik.handleChange}
                        value={formik.values.prescription_length}
                        onBlur={estimateExitDate}
                        error={formik.errors.prescription_length}
                    />
                    <Form.Input
                        fluid
                        readOnly
                        label="Expected Exit Date"
                        placeholder="Expected Exit Date"
                        name="expectedExitDate"
                        width={2}
                        onChange={formik.handleChange}
                        value={formik.values.estimated_exit}
                    />
                <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Actual Exit Date"
                        placeholder="Actual Exit Date"
                        name="unenrolled_at"
                        width={2}
                        onChange={formik.handleChange}
                        value={formik.values.unenrolled_at}
                    />
                </Form.Group>
                <Form.Input
                        fluid
                        readOnly={true}
                        label="Date of Last Therapy"
                        name="date_of_last_therapy"
                        width={3}
                        value={dateOfLastTherapy ? dateOfLastTherapy : "No therapy data"}
                />
                <Form.TextArea
                    fluid
                    readOnly={viewMode}
                    label="Indication"
                    placeholder="VF/VT"
                    name="indication"
                    onChange={formik.handleChange}
                    error={formik.errors.indication}
                    value={formik.values.indication}
                    width={6}
                />
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 1"
                        placeholder="Shipping Address Line 1"
                        name="permanent_address.street_address_1"
                        onChange={formik.handleChange}
                        error={formik.errors.permanent_address?.street_address_1}
                        value={formik.values.permanent_address?.street_address_1}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="City"
                        placeholder="City"
                        name="permanent_address.city"
                        onChange={formik.handleChange}
                        error={formik.errors.permanent_address?.city}
                        value={formik.values.permanent_address?.city}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="State"
                        placeholder="State"
                        name="permanent_address.state"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.permanent_address?.state}
                        value={formik.values.permanent_address?.state}
                        width={2}
                        options={States}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 2"
                        placeholder="(Apt, Bldg, Unit)"
                        name="permanent_address.street_address_2"
                        onChange={formik.handleChange}
                        error={formik.errors.permanent_address?.street_address_2}
                        value={formik.values.permanent_address?.street_address_2}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Zip Code"
                        placeholder="Zip Code"
                        name="permanent_address.postal_code"
                        onChange={formik.handleChange}
                        error={formik.errors.permanent_address?.postal_code}
                        value={formik.values.permanent_address?.postal_code}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Country"
                        placeholder="Country"
                        name="permanent_address.country"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.permanent_address?.country}
                        value={formik.values.permanent_address?.country}
                        options={Countries}
                        width={2}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Patient Mobile Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="mobile_phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.mobile_phone_number}
                        value={formik.values.mobile_phone_number}
                        width={2}/>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Patient Landline Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="landline_phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.landline_phone_number}
                        value={formik.values.landline_phone_number}
                        width={2}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Patient Email"
                        placeholder="Email"
                        name="email"
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        value={formik.values.email}
                        width={2}
                    />
                    <Form.Input label='Preferred Phone' name='preferred_phone' value={formik.values.preferred_phone}>
                        <Checkbox
                            toggle
                            style={{marginLeft:'15px', marginTop:'10px'}}
                            label={preferredPhone}
                            disabled={viewMode}
                            checked={preferredPhone=='mobile'?false:true}
                            onChange={() => {preferredPhone==='mobile'?setPreferredPhone('landline'):setPreferredPhone('mobile')}}
                        />
                    </Form.Input>


                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Emergency Contact Name"
                        placeholder="First and Last Name"
                        name="emergency_contact_name"
                        onChange={formik.handleChange}
                        error={formik.errors.emergency_contact_name}
                        value={formik.values.emergency_contact_name}
                        width={2}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Relationship to Patient"
                        placeholder="(Brother, Sister, Mother)"
                        name="emergency_contact_relationship"
                        onChange={formik.handleChange}
                        error={formik.errors.emergency_contact_relationship}
                        value={formik.values.emergency_contact_relationship}
                        width={2}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Emergency Contact Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="emergency_contact_number"
                        onChange={formik.handleChange}
                        error={formik.errors.emergency_contact_number}
                        value={formik.values.emergency_contact_number}
                        width={2}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Using Mobile App?"
                        name="is_mobile_app_user"
                        options={booleanOptions}
                        onChange={(event, data) => {
                            if (data.value) {
                                handleOptionChange(true, "is_mobile_app_user")
                                setUsingMobilePhone(true);
                            } else {
                                handleOptionChange(false, "is_mobile_app_user")
                                setUsingMobilePhone(false);
                            }
                        }}
                        error={formik.errors.is_mobile_app_user}
                        value={formik.values.is_mobile_app_user}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode || !usingMobilePhone}
                        label="iPhone Model"
                        name="mobile_model"
                        defaultValue=""
                        options={iphoneOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.mobile_model}
                        value={formik.values.mobile_model}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode || !usingMobilePhone}
                        label="Operating System Version"
                        name="mobile_os_version"
                        options={iosOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.mobile_os_version}
                        value={formik.values.mobile_os_version}
                        width={2}
                    />
                </Form.Group>
            </div>
            <div className="pt-5">
                <Header as='h4' style={{ color:'#3F2A57'}} dividing>Patient's Facility and Provider</Header>
                <Form.Group>
                    <Form.Select
                        fluid
                        search
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Site Name"
                        name="facility_name"
                        onChange={(event, data) => {
                            handleSiteChange(data);
                        }}
                        error={formik.errors.facility_name}
                        value={formik.values.facility_name }
                        options={sites ? sites : []}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        label="Site Investigator Name"
                        placeholder="(Auto Populated)"
                        name="site_investigator_name"
                        error={formik.errors.site_investigator_name}
                        value={formik.values.site_investigator_name}
                        onChange={formik.handleChange}
                        width={2}
                        readOnly={true}
                    />
                    <Form.Input
                        fluid
                        label="Study Coordinator Name"
                        placeholder="(Auto Populated)"
                        name="study_coordinator_name"
                        error={formik.errors.study_coordinator_name}
                        value={formik.values.study_coordinator_name}
                        onChange={formik.handleChange}
                        width={2}
                        readOnly={true}
                    />
                    
                    
                </Form.Group>
                <Checkbox
                    className="pt-3 pb-4"
                    label="Prescribing Physician same as Site Investigator"
                    onChange={(event, data) => handleCheckbox(data)}
                    checked={isChecked}
                    readOnly={viewMode}
                    disabled={viewMode}
                />
                <Form.Group>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={disableProvider || viewMode}
                        label="Prescribing Provider"
                        name="prescribing_provider"
                        onChange={(event, data) => {
                            handleProviderChange(data);
                        }}
                        error={formik.errors.prescribing_provider}
                        value={formik.values.prescribing_provider}
                        options={formik.values.facility_name ? providerList : []}
                        width={2}
                    />
                    <Form.Input
                        fluid
                        disabled={disableProvider}
                        placeholder="(Auto Populated)"
                        label="Prescribing Provider Number"
                        name="prescribing_provider_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.prescribing_provider_number}
                        value={formik.values.prescribing_provider_number}
                        width={2}
                        readOnly={true}
                    />
                    <Form.Input
                        fluid
                        disabled={disableProvider}
                        placeholder="(Auto Populate)"
                        label="Prescribing Provider Email"
                        name="prescribing_provider_email"
                        onChange={formik.handleChange}
                        error={formik.errors.prescribing_provider_email}
                        value={formik.values.prescribing_provider_email}
                        width={2}
                        readOnly={true}
                    />
                </Form.Group>

            <span>
                <>{(disabledProviders[formik.values.prescribing_provider]=="DISABLED")?<Label color='red' content="Warning Provider is DISABLED - Please Update"/>:<></>}</>
            </span>

            </div>
            <div className="pt-5">
                <Header as='h4' style={{ color:'#3F2A57'}} dividing>Patient's Device & Patches</Header>
                <Form.Group>
                    <Form.Input
                        fluid
                        label="Defib Unit Serial Number"
                        name="device_serial_number"
                        readOnly={viewMode}
                        onChange={formik.handleChange}
                        error={formik.errors.device_serial_number}
                        value={formik.values.device_serial_number}
                        placeholder="1XXXXXXX"
                        width={2}/>
                    <Form.Input
                        fluid
                        label="Starter Kit Lot Number"
                        name="starter_kit_lot_number"
                        readOnly={viewMode}
                        onChange={formik.handleChange}
                        error={formik.errors.starter_kit_lot_number}
                        value={formik.values.starter_kit_lot_number}
                        placeholder="EYYMMXXXX"
                        width={2}/>
                    <Form.Input
                        fluid
                        label="Applied Patch Kit Lot Number"
                        name="applied_patch_lot_number"
                        readOnly={viewMode}
                        onChange={formik.handleChange}
                        error={formik.errors.applied_patch_lot_number}
                        value={formik.values.applied_patch_lot_number}
                        placeholder="EYYMMXXXX"
                        width={2}/>
                    <Form.Input
                        fluid
                        label="Unused Patch Kit Lot Number"
                        name="unused_patch_lot_number"
                        readOnly={viewMode}
                        onChange={formik.handleChange}
                        error={formik.errors.unused_patch_lot_number}
                        value={formik.values.unused_patch_lot_number}
                        placeholder="EYYMMXXXX"
                        width={2}/>
                </Form.Group>
            </div>
            { previousDevices !== null && <div style={{ paddingLeft:'3px'}}>
                 <Header as='h6' style={{ paddingTop:'20px'}}>Previous Devices</Header>
                 <List>
                    {previousDevices.map( x => <List.Item style={{ color:'#d32c2c'}}>
                        <List.Icon name='mobile alternate' />
                        <List.Content>{x}</List.Content>
                        </List.Item>)}
                 </List>
                 </div>
            }
            <div className="pt-5">
                <Form.Group>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="PA Setting - Front"
                        placeholder="Select Setting"
                        name="pa_setting_front"
                        options={lowerPatchFrontSettingOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.pa_setting_front}
                        value={formik.values.pa_setting_front}
                        width={2}/>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="PA Setting - Back"
                        name="pa_setting_back"
                        placeholder="Select Setting"
                        options={lowerPatchBackSettingOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.pa_setting_back}
                        value={formik.values.pa_setting_back}
                        width={2}/>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Upper Patch Setting"
                        name="upper_patch_setting"
                        placeholder="Select Setting"
                        options={upperPatchPadSettingOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.upper_patch_setting}
                        value={formik.values.upper_patch_setting}
                        width={2}/>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Shoulder Strap Front"
                        name="shoulder_strap_front"
                        placeholder="Select Setting"
                        options={frontShoulderStrapSettingOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.shoulder_strap_front}
                        value={formik.values.shoulder_strap_front}
                        width={2}/>
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Shoulder Strap Back"
                        name="shoulder_strap_back"
                        placeholder="Select Setting"
                        options={backShoulderStrapSettingOptions}
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.shoulder_strap_back}
                        value={formik.values.shoulder_strap_back}
                        width={2}/>
                </Form.Group>
                <Form.Group className="pt-5">
                    <Form.TextArea
                        fluid
                        readOnly={viewMode}
                        label="Enrollment Notes"
                        placeholder="Write notes"
                        name="enrollment_notes"
                        onChange={formik.handleChange}
                        error={formik.errors.enrollment_notes}
                        value={formik.values.enrollment_notes}
                        width={8}
                    />
                </Form.Group>
            </div>
            <div className="pt-5 mt-5 pl-2">
                <Form.Group>
                    <div className="pr-3">
                        <Button 
                            type="submit"
                            primary
                            onSubmit={formik.handleSubmit}
                            disabled={formik.isSubmitting || viewMode}  
                        >
                            {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Submit
                        </Button>
                    </div>
                    <Button secondary as={ Link } name='patients' to='/patients'>Cancel</Button>
                    <Button
                        className="ml-3"
                        type="button" 
                        name="resend_button"
                        disabled={!viewMode}
                        onClick={handleResendLoginInfo}
                        >Reset Login Info
                    </Button>
                </Form.Group>
            </div>
        </Form>
    );
}

export { AddEdit };
