import React, { useState, useEffect, useReducer, useRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Icon, Table, Popup, Grid, Dropdown, Checkbox } from 'semantic-ui-react';
import { patientService, alertService, providerService } from '../_services';
import SearchBar from '../_components/SearchBar';
import RecordsPerPageSelection from '../_components/RecordsPerPageSelection';


function List(props) {
    const history = useHistory();
    const { path } = props.match;
    const [selRecordsPerPage, setSelRecordsPerPage] = useState(sessionStorage.getItem("record_per_page"));
    const [disabledProviders,setDisableProviders] = useState({});
    const [patients, setPatients] = useState(null);
    const [state, setState] = useState({
        column: null,
        data: null,
        direction: null
    });
    const [historyView, setHistoryView] = useState(JSON.parse(sessionStorage.getItem('historyView')));
    const { column, data, direction } = state
    const [params, setParams] = useState({
        name: "",
        record_per_page: sessionStorage.getItem('record_per_page'),
        page_number: 0
    });
    const [snSearch, setSNSearch] = useState(false);

    const handleSearch = (searchValue) => {
        search(searchValue);
    };

    const handleSort = (event) => {
        if (column !== event.column) {
            setState({
                column: event.column,
                data: _.sortBy(event.data, [event.column]),
                direction: "ascending"
            });
            setPatients({
                data: _.sortBy(event.data.data, [event.column]),
                record_per_page: patients.record_per_page,
                page_number: patients.page_number,
                status_code: patients.status_code,
                total: patients.total
            });
            return;
        }
        setState({
            column: event.column,
            data: patients.data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    const handlePagination = (page) => {
        patientService.getAll({
            name: "",
            record_per_page: sessionStorage.getItem('record_per_page'),
            page_number: Math.ceil(page)
        })
        .then(x => {
            if (x) {
                const enrollment_statuses = {};
                x.data.map(item => {
                    enrollment_statuses[item.patient_id] = item.enrollment_status;
                });
                sessionStorage.setItem("enrollment_statuses", JSON.stringify(enrollment_statuses));
            }
            setPatients(x);
        }).catch(err => {
            console.log(err);
            if(err == "Unauthorized: 'Invalid Token.'."){
                history.push('/logout');
            }
        });
        providerService.getAll({
            name: "",
            record_per_page: 0,
            page_number: 0
        })
        .then(x => {
            let disabledProvMap = {};
            x.data.map(y => {
                disabledProvMap[y.provider_name] = y.status_id;
            });
            setDisableProviders(disabledProvMap);
        });
        
    }

    const search = (searchParams) => {
        searchParams["serial_number"] = snSearch?searchParams["external_id"]:"";
        searchParams["external_id"] = snSearch?"":searchParams["external_id"]; 
        patientService.getAll(searchParams)
        .then(x => {
            if (x) {
                const enrollment_statuses = {};
                x.data.map(item => {
                    enrollment_statuses[item.patient_id] = item.enrollment_status;
                });
                sessionStorage.setItem("enrollment_statuses", JSON.stringify(enrollment_statuses));
            }
            setPatients(x);
        });
    }
   
    const handleRPP = (e, data) => {
        setSelRecordsPerPage(data.value);
        setParams( {
            name: snSearch?"":params.name,
            serial_number:snSearch?params.name:"",  
            record_per_page: selRecordsPerPage, 
            page_number: params.page_number
        });
    };

    useEffect(() => {
        handlePagination(params.page_number);
    }, [selRecordsPerPage]);

    useEffect(() => { patientService
        patientService.getAll(params)
        .then(x => {
            if (x) {
                const enrollment_statuses = {};
                x.data.map(item => {
                    enrollment_statuses[item.patient_id] = item.enrollment_status;
                });
                sessionStorage.setItem("enrollment_statuses", JSON.stringify(enrollment_statuses));
            }
            setPatients(x);
        })
        .catch(error => {
            alertService.error(error);
        });
    }, []);
    
    const addToHistory = (patient_name, patient_id) => {
        const newPat = {key: patient_name, value: patient_id, text: patient_name};
        if (historyView.length == 5){
            historyView.shift()
        }
        if(historyView.some(e => e.key == patient_name) === false){
            historyView.push(newPat);
            sessionStorage.setItem('historyView', JSON.stringify(historyView));
            setHistoryView(historyView);
        }
    }

    const historySelection = (choice) => {
        for(let i = 0; i < historyView.length; i++){
            if(historyView[i]['value'] == choice['value']){
                history.push(`patients/view/${historyView[i]['value']}`)
            }
        }
    }

    return (
        <div>
            <div className="mt-4 pt-2">
                <div className="ui two column grid" width="100%">
                    <div className="four wide column column-addon">
                        <SearchBar handleSearch={handleSearch}/>
                    </div>
                    <div className='two wide column column-addon'>
                       <Checkbox 
                        label='Search by SN#'
                        style={{'padding-top': '0.65rem'}}
                        onChange={(event,choice) => {setSNSearch(choice.checked)}}
                       />
                    </div>
                    <div className='eight wide column column-addon'>
                        <Grid>
                            <Grid.Column width={1}/>
                            <Grid.Column width={4} >
                                <Dropdown
                                text='View History' 
                                placeholder='View History' 
                                fluid
                                simple
                                style={{'padding-top': '0.40rem'}}
                                onChange={(event, choice) => {historySelection(choice)}}
                                options={historyView}
                                />
                            </Grid.Column>
                        </Grid>
                        
                    </div>
                    <div className="two wide column column-addon">
                        <RecordsPerPageSelection handleSelection={ handleRPP } />
                    </div>
                </div>
                <Table striped sortable>
                    <Table.Header>
                        <Table.HeaderCell
                            sorted={column == 'name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'name', data: patients})}
                            style={{width:'220px'}}
                        >
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'patient_id' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'patient_id', data: patients})}
                        >
                            Patient ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'provider' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'provider_name', data: patients})}
                        >
                            Prescribing Provider
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'created_on' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'enrolled_on', data: patients})}
                        >
                            Date Enrolled
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'device_serial_number' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'site', data: patients})}
                        >
                            Site
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'primary_contact' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'primary_contact', data: patients})}
                        >
                            Preferred Contact
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'serial_number' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'serial_number', data: patients})}
                        >
                            Serial Number
                        </Table.HeaderCell>
                    </Table.Header>

                    <Table.Body>
                        {patients && patients.data.map(patient =>
                            <Table.Row key={patient.patient_id}>
                                <Table.Cell selectable style={{display:'flex', alignItems:'center', valign:'middle', verticalAlign:"middle"}}> 
                                    <Popup content={patient.enrollment_status === "ENROLLED" ? "Enrolled" : "Deactivated"} trigger={patient.enrollment_status === "ENROLLED" ? <Icon style={{paddingLeft: '10px', paddingBottom: '19px'}} name='check circle' color='green'/> : <Icon style={{paddingLeft: '10px', paddingBottom: '19px'}}  name='minus circle' color='red'/>}></Popup>
                                    <Link onClick={() => {addToHistory(patient.name, patient.patient_id)}} to={`${path}/view/${patient.patient_id}`}>{patient.name}</Link>
                                </Table.Cell>
                                <Table.Cell>{patient.external_id}</Table.Cell>
                                <Table.Cell className={disabledProviders[patient.provider_name]==="DISABLED"?"error":"default" }>{patient.provider_name ? patient.provider_name : "N/A"}</Table.Cell>
                                <Table.Cell>{patient.enrolled_on}</Table.Cell>
                                <Table.Cell>{patient.site ? patient.site : "N/A"}</Table.Cell>
                                <Table.Cell>{!patient.primary_contact.includes("-") && (patient.primary_contact.length < 11) ? patient.primary_contact.slice(0,3)+"-"+patient.primary_contact.slice(3,6)+"-"+patient.primary_contact.slice(6) : patient.primary_contact}</Table.Cell>            
                                <Table.Cell>{patient.serial_number}</Table.Cell>
                            </Table.Row>
                        )}
                        {!patients &&
                            <Table.Row>
                                <Table.Cell colSpan="6" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {patients && !patients.data.length &&
                            <Table.Row>
                                <Table.Cell colSpan="6" className="text-center">
                                    <div className="p-2">No Patients To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Pagination className="mt-5 mb-5"
                        onPageChange={(event, data) => handlePagination(data.activePage-1)}
                        defaultActivePage={patients ? patients.page_number : 1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={patients ? Math.ceil(patients.total / patients.record_per_page) : 0}
                    />
                </div>
            </div>
        </div>
    );
}

export { List };
