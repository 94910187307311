import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    Form,
    Button,
    Divider,
    Grid,
    Header,
    Checkbox
} from 'semantic-ui-react';

import { 
    alertService, 
    providerService,
    facilityService,
    userService
} from '../_services';

import {
    Countries,
    States
} from '../models/Constants';

import { Link } from 'react-router-dom';
import { formatMs } from '@material-ui/core';
import { verifyAddress } from '../_helpers/verify-address';

function AddEdit({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const pathname = window.location.pathname;
    const [isLoading, setLoading] = useState(false);
    const [facility, setFacility] = useState({});
    const [clinicalSiteManagers, setClinicalSiteManagers] = useState([]);
    const [clinicalSiteProviders,setClinicalSiteProviders] = useState([]);
    const [siteInvestigator, setSiteInvestigator] = useState({});
    const [primaryStudyCoordinator, setPrimaryStudyCoordinator] = useState({});
    const [numStudyCoordinators, setNumStudyCoordinators] = useState(1);
    const [otherStudyCoordinator1, setOtherStudyCoordinator1] = useState({});
    const [otherStudyCoordinator2, setOtherStudyCoordinator2] = useState({});
    const [otherStudyCoordinator3, setOtherStudyCoordinator3] = useState({});
    const [otherStudyCoordinator4, setOtherStudyCoordinator4] = useState({});
    const [useNewSiteInvestigator, setUseNewSiteInvestigator] = useState(true);
    const [usePOinPDR, setUsePOinPDR] = useState(false);
    // View mode
    const [viewMode, setViewMode] = useState(false);

    // form validation rules
    const validationSchema = Yup.object().shape({
        site_info: Yup.object().shape({
            facility_name: Yup.string()
                .required('Name is required'),
            external_facility_id: Yup.string()
                .min(5, 'Site ID must be in the format SS-HHH')
                .max(8, 'Site ID must be in the format SS-HHH')
                .matches(/^[a-zA-Z]{2}-[a-zA-Z]{3}$/, 'Site ID must be in the format SS-HHH')
                .required('External Site ID is required'),
            address: Yup.object().shape({
                street_address_1: Yup.string()
                    .required('Street Address 1 is required')
                    .max(100, "Street Address must be 100 characters or less"),
                street_address_2: Yup.string()
                    .max(100, "Street Address must be 100 characters or less"),
                city: Yup.string()
                    .required('City is required')
                    .max(50, "City must be 50 characters or less"),
                state: Yup.string()
                    .required('State is required'),
                postal_code: Yup.string()
                    .required('Postal Code is required'),
                country: Yup.string()
                    .required("Country is required"),
            }),
            primary_contact_id: Yup.string()
                .required('Clinical Site Manager name is required'),
            all_day_phone: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
            alt_recipient_first_name: Yup.string(),
            alt_recipient_last_name: Yup.string(),
            alt_recipient_phone: Yup.string()
                        .max(12, 'Phone number must be no more than 12 digits long')
                        .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
            alt_recipient_email: Yup.string()
        }),
        site_investigator: Yup.object().shape({
            first_name: Yup.string()
                .required('First Name is required'),
            last_name: Yup.string()
                .required('Last Name is required'),
            external_user_id: Yup.string()
                .min(10, 'Must be exactly 10 digits')
                .max(10, 'Must be exactly 10 digits')
                .required('NPI is required'),
            email: Yup.string()
                .required('Email is required'),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx')
                .required('Phone Number is required'),
            id: Yup.number()
        }),
        primary_study_coordinator: Yup.object().shape({
            first_name: Yup.string()
                .required('First Name is required'),
            last_name: Yup.string()
                .required('Last Name is required'),
            email: Yup.string()
                .required('Email is required'),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx')
                .required('Phone Number is required'),
        }),
        other_study_coordinator_1: Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            email: Yup.string(),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        }),
        other_study_coordinator_2: Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            email: Yup.string(),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        }),
        other_study_coordinator_3: Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            email: Yup.string(),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        }),
        other_study_coordinator_4: Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            email: Yup.string(),
            phone_number: Yup.string()
                .max(12, 'Phone number must be no more than 12 digits long')
                .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx'),
        })
    });

    const formik = useFormik({
        initialValues: {
            site_info: {
                facility_name: "",
                external_facility_id: "",
                address: {
                    street_address_1: "",
                    street_address_2: "",
                    city: "",
                    state: "",
                    postal_code: "",
                    country: ""
                },
                primary_contact_id: "",
                on_call_phone: "",
                all_day_phone: "",
                po_number: "",
                use_po_in_pdr: "",
                alt_recipient_first_name: "",
                alt_recipient_last_name: "",
                alt_recipient_phone: "",
                alt_recipient_email: ""

            },
            site_investigator: {
                id:"",
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "OUTPATIENT_PROVIDER",
                is_primary: false
            },
            primary_study_coordinator: {
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "FACILITY_ADMIN",
                is_primary: true
            },
            other_study_coordinator_1: {
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "FACILITY_ADMIN",
                is_primary: false
            },
            other_study_coordinator_2: {
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "FACILITY_ADMIN",
                is_primary: false
            },
            other_study_coordinator_3: {
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "FACILITY_ADMIN",
                is_primary: false
            },
            other_study_coordinator_4: {
                first_name: "",
                last_name: "",
                external_user_id: "",
                email: "",
                phone_number: "",
                facility_id: "",
                facilities_to_assign:[],
                facilities_to_unassign:[],
                facilities:[],
                role_name: "FACILITY_ADMIN",
                is_primary: false
            }
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    // functions to build form returned by useForm() hook
    const { setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    async function onSubmit(data) {

        // Strip dashes from numbers
        data["site_info"]["all_day_phone"] = data["site_info"]["all_day_phone"].replace(/-/g, "");
        data["site_investigator"]["phone_number"] = data["site_investigator"]["phone_number"].replace(/-/g, "");
        data["site_investigator"]["phone_number"] = data["site_investigator"]["phone_number"].replace(/-/g, "");
        data["primary_study_coordinator"]["phone_number"] = data["primary_study_coordinator"]["phone_number"].replace(/-/g, "");
        if (data["site_info"]["alt_recipient_phone"]){
            data["site_info"]["alt_recipient_phone"] = data["site_info"]["alt_recipient_phone"].replace(/-/g, "");
        }
        if (data["other_study_coordinator_1"]["phone_number"]) {
            data["other_study_coordinator_1"]["phone_number"] = data["other_study_coordinator_1"]["phone_number"].replace(/-/g, "");
        }
        if (data["other_study_coordinator_2"]["phone_number"]) {
            data["other_study_coordinator_2"]["phone_number"] = data["other_study_coordinator_2"]["phone_number"].replace(/-/g, "");
        }
        if (data["other_study_coordinator_3"]["phone_number"]) {
            data["other_study_coordinator_3"]["phone_number"] = data["other_study_coordinator_3"]["phone_number"].replace(/-/g, "");
        }
        if (data["other_study_coordinator_4"]["phone_number"]) {
            data["other_study_coordinator_4"]["phone_number"] = data["other_study_coordinator_4"]["phone_number"].replace(/-/g, "");
        }

        let verified_address = await verifyAddress(data['site_info']['address'])
        if (verified_address != null){
            data['site_info']['address'] = verified_address;
        }else{
            formik.setSubmitting(false);
            return alertService.error('Address is not valid. Please enter a valid address.');
        }

        return isAddMode
            ? createFacilityAndProviders(data)
            : updateFacilityAndProviders(id, data);
    }

    function createFacilityAndProviders(data) {
        // Site on call phone comes from primary study coordinator
        data.site_info.on_call_phone = data.primary_study_coordinator.phone_number;
        data.site_info.use_po_in_pdr = usePOinPDR;
        return facilityService.create(data.site_info)
            .then((facilityResponse) => {
                const facilityId = facilityResponse.facility_id.toString();

                data.site_investigator.facility_id = facilityId;
                data.primary_study_coordinator.facility_id = facilityId;
                data.primary_study_coordinator.facilities.push({"id": facilityId, "is_primary": true})
                data.site_investigator.facilities.push({"id": facilityId, "is_primary": false})
                data.site_investigator.facilities_to_assign.push({"id": facilityId, "is_primary": false})
                var siFacilitiy = new Promise(() => {});
                if(!useNewSiteInvestigator){
                    delete data.site_investigator.id;
                    delete data.site_investigator.facilities;
                    delete data.site_investigator.role_name;
                    delete data.site_investigator.is_primary;
                    delete data.site_investigator.facility_id;
                    siFacilitiy = providerService.update(siteInvestigator, data.site_investigator)
                } else {
                    delete data.site_investigator.id;
                    delete data.site_investigator.is_primary;
                    delete data.site_investigator.facility_id;
                    delete data.site_investigator.facilities_to_assign;
                    siFacilitiy = providerService.create(data.site_investigator)
                }
                siFacilitiy.then((siteInvestigatorResponse) => {
                    providerService.create(data.primary_study_coordinator)
                    .then((studyCoordinatorResponse) => {
                        if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_1)) {
                            data.other_study_coordinator_1.facility_id = facilityResponse.facility_id;
                            data.other_study_coordinator_1.facilities.push({"id":facilityResponse.facility_id, "is_primary":false});
                            providerService.create(data.other_study_coordinator_1)
                            .then((studyCoordinatorResponse) => {
                                if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_2)) {
                                    data.other_study_coordinator_2.facility_id = facilityResponse.facility_id;
                                    data.other_study_coordinator_2.facilities.push({"id": facilityResponse.facility_id, "is_primary":false});

                                    providerService.create(data.other_study_coordinator_2)
                                    .then((studyCoordinatorResponse) => {
                                       if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_3)){
                                        data.other_study_coordinator_3.facility_id = facilityResponse.facility_id;
                                        data.other_study_coordinator_3.facilities.push({"id":facilityResponse.facility_id, "is_primary":false});

                                         providerService.create(data.other_study_coordinator_3)
                                         .then((studyCoordinatorResponse) =>{
                                             if(!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_4)){
                                                data.other_study_coordinator_4.facility_id = facilityResponse.facility_id;
                                                data.other_study_coordinator_4.facilities.push({"id":facilityResponse.facility_id, "is_primary":false});

                                                providerService.create(data.other_study_coordinator_4)
                                             }
                                             else{
                                                alertService.success('Facility and providers added', { keepAfterRouteChange: true });
                                                history.push('.');
                                             }
                                         })
                                       }
                                       else {
                                        alertService.success('Facility and providers added', { keepAfterRouteChange: true });
                                        history.push('.');
                                       }
                                    })
                                }
                                else {
                                    alertService.success('Facility and providers added', { keepAfterRouteChange: true });
                                    history.push('.');
                                }
                            })

                        }
                        else {
                            alertService.success('Facility and providers added', { keepAfterRouteChange: true });
                            history.push('.');
                        }
                    })
                    .catch((err) => {
                        formik.setSubmitting(false);
                        alertService.error(err);
                    })
                })
                .catch((err) => {
                    formik.setSubmitting(false);
                    alertService.error(err);
                })
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function updateFacilityAndProviders(id, data) {
        var id = id.toString();
        // Site on call phone comes from primary study coordinator
        data.site_info.on_call_phone = data.primary_study_coordinator.phone_number;
        if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_1)) {
            let countSC = numStudyCoordinators;
            setNumStudyCoordinators(countSC++)

        }
        if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_2)) {
            let countSC = numStudyCoordinators;
            setNumStudyCoordinators(countSC++)
        }
        if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_3)) {
            let countSC = numStudyCoordinators;
            setNumStudyCoordinators(countSC++)
        }
        if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_4)) {
            let countSC = numStudyCoordinators;
            setNumStudyCoordinators(countSC++)
        }
        data.site_info.use_po_in_pdr = usePOinPDR;
        data.site_info.po_number = formik.values.site_info.po_number;
        return facilityService.update(id, data.site_info)
            .then((facilityResponse) => {
                data.site_investigator.facilities_to_assign.push({"id":id, "is_primary":false});
                delete data.site_investigator.role_name;
                delete data.site_investigator.is_primary;
                delete data.site_investigator.facility_id;
                delete data.site_investigator.facilities;
                delete data.site_investigator.id;

                data.primary_study_coordinator.facilities_to_assign.push({"id":id, "is_primary":false});
                delete data.primary_study_coordinator.role_name;
                delete data.primary_study_coordinator.is_primary;
                delete data.primary_study_coordinator.facility_id;
                delete data.primary_study_coordinator.facilities;

                providerService.update(siteInvestigator, data.site_investigator)
                .then((siteInvestigatorResponse) => {
                    providerService.update(primaryStudyCoordinator, data.primary_study_coordinator)
                    .then((studyCoordinatorResponse) => {
                        if (numStudyCoordinators > 1) {

                            data.other_study_coordinator_1.facilities_to_assign.push({"id":id, "is_primary":false});
                            delete data.other_study_coordinator_1.role_name;
                            delete data.other_study_coordinator_1.is_primary;
                            delete data.other_study_coordinator_1.facility_id;
                            delete data.other_study_coordinator_1.facilities;

                            providerService.update(otherStudyCoordinator1, data.other_study_coordinator_1)
                            .then((studyCoordinatorResponse) => {
                                if (numStudyCoordinators > 2) {

                                    data.other_study_coordinator_2.facilities_to_assign.push({"id":id, "is_primary":false});
                                    delete data.other_study_coordinator_2.role_name;
                                    delete data.other_study_coordinator_2.is_primary;
                                    delete data.other_study_coordinator_2.facility_id;
                                    delete data.other_study_coordinator_2.facilities;
                                    providerService.update(otherStudyCoordinator2, data.other_study_coordinator_2)
                                    .then((studyCoordinatorResponse) => {
                                        if (numStudyCoordinators > 3) {

                                            data.other_study_coordinator_3.facility_id = id;
                                            delete data.other_study_coordinator_3.role_name;
                                            delete data.other_study_coordinator_3.is_primary;
                                            delete data.other_study_coordinator_3.facility_id;
                                            delete data.other_study_coordinator_3.facilities;
                                            providerService.update(otherStudyCoordinator3, data.other_study_coordinator_3)
                                            .then((studyCoordinatorResponse) =>{
                                                if (numStudyCoordinators > 4) {

                                                    data.other_study_coordinator_4.facilities_to_assign.push({"id":id, "is_primary":false});
                                                    delete data.other_study_coordinator_4.role_name;
                                                    delete data.other_study_coordinator_4.is_primary;
                                                    delete data.other_study_coordinator_4.facility_id;
                                                    delete data.other_study_coordinator_4.facilities;

                                                    providerService.update(otherStudyCoordinator4, data.other_study_coordinator_4)
                                                }
                                                else if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_4)) {
                                                    console.log("Creating other_study_coordinator_4...");
                                                    data.other_study_coordinator_4.facilities.push({"id":id, "is_primary":false});
                                                    delete data.other_study_coordinator_4.is_primary;
                                                    delete data.other_study_coordinator_4.facility_id;
                                                    delete data.other_study_coordinator_4.facilities_to_assign;

                                                    providerService.create(data.other_study_coordinator_4)
                                                    .then((studyCoordinatorResponse) => {
                                                    console.log("Facility and providers updated"); })
                                                }
                                                console.log("Facility and providers updated");
                                            })
                                        }
                                        else if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_3)){
                                            console.log("Creating other_study_coordinator_3...");
                                            data.other_study_coordinator_3.facilities.push({"id":id, "is_primary":false});
                                            delete data.other_study_coordinator_3.is_primary;
                                            delete data.other_study_coordinator_3.facility_id;
                                            delete data.other_study_coordinator_3.facilities_to_assign;
                                            providerService.create(data.other_study_coordinator_3)
                                            .then((studyCoordinatorResponse) => {
                                            console.log("Facility and providers updated"); })
                                        }
                                        console.log("Facility and providers updated");
                                    })
                                }
                                else if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_2)) {
                                    console.log("Creating other_study_coordinator_2...");
                                    data.other_study_coordinator_2.facility_id = id;
                                    delete data.other_study_coordinator_2.is_primary;
                                    delete data.other_study_coordinator_2.facility_id;
                                    delete data.other_study_coordinator_2.facilities_to_assign;
                                    providerService.create(data.other_study_coordinator_2)
                                    .then((studyCoordinatorResponse) => {
                                        console.log("Facility and providers updated");
                                    })
                                }
                                console.log("Facility and providers updated");
                            })
                        }
                        else {
                            // Other study coordinators did not exist, they need to be created
                            if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_1)) {
                                data.other_study_coordinator_1.facility_id = id;
                                data.other_study_coordinator_1.facilities.push({"id": id, "is_primary": false});

                                providerService.create(data.other_study_coordinator_1)
                                .then((studyCoordinatorResponse) => {
                                    if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_2)) {
                                        data.other_study_coordinator_2.facility_id = id;
                                        data.other_study_coordinator_2.facilities.push({"id": id, "is_primary": false});
                                        providerService.create(data.other_study_coordinator_2)
                                        .then((studyCoordinatorResponse) => {
                                            if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_3)){
                                                data.other_study_coordinator_3.facility_id = id;
                                                data.other_study_coordinator_3.facilities.push({"id": id, "is_primary": false});
                                                providerService.create(data.other_study_coordinator_3)
                                                .then((studyCoordinatorResponse) => {
                                                    if (!isOtherStudyCoordinatorDataEmpty(data.other_study_coordinator_4)){
                                                        data.other_study_coordinator_4.facility_id = id;
                                                        data.other_study_coordinator_4.facilities.push({"id": id, "is_primary": false});
                                                        providerService.create(data.other_study_coordinator_4)
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    console.log("Facility and providers updated");
                                })
                            }
                        }
                    })
                })
                alertService.success('Facility and providers updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    const handleOptionChange = (option, field) => {
        formik.setFieldValue(field, option);
    }

    const handleProviderOptionChange = (value, data, field) => {
        const siteInvestigator = data.find(function( prov ) {
            return prov.value == value;
          });
        setSiteInvestigator(value);
        formik.setFieldValue(field, value);
        formik.setFieldValue('site_investigator.first_name', siteInvestigator.data.first_name);
        formik.setFieldValue('site_investigator.last_name', siteInvestigator.data.last_name);
        formik.setFieldValue('site_investigator.external_user_id', siteInvestigator.data.npi);
        formik.setFieldValue('site_investigator.email', siteInvestigator.data.email);
        formik.setFieldValue('site_investigator.phone_number', siteInvestigator.data.phone_number);
    }

    const loadClinicalSiteManagers = () => {
        userService.getUserByRole("ES_CLINICAL_SITE_MANAGER")
        .then((_clinicalSiteManagers) => {
            if(_clinicalSiteManagers) {
                // Set Clinical Site Manager options
                const clinicalSiteManagerOptions = [];
                _clinicalSiteManagers.data[0].map(x => {
                    clinicalSiteManagerOptions.push(
                        {
                            key: x.user_id,
                            text: x.first_name + " " + x.last_name,
                            value: x.user_id
                        }
                    );
                });
                setClinicalSiteManagers(clinicalSiteManagerOptions);
            }
        })
        .catch((err) => {
            console.log("No Clinical Site Managers found!");
        });
    }

    const loadClinicalProviders = () => {
        userService.getUserByRole("OUTPATIENT_PROVIDER")
        .then((_clinicalProviders) => {
            if(_clinicalProviders) {
                // Set Clinical Providers options
                const clinicalSiteProvidersOptions = [];
                _clinicalProviders.data[0].map(x => {
                    clinicalSiteProvidersOptions.push(
                        {
                            key: x.user_id,
                            text: x.first_name + " " + x.last_name,
                            value: x.user_id,
                            data: x
                        }
                    );
                });
                setClinicalSiteProviders(clinicalSiteProvidersOptions);
            }
        })
        .catch((err) => {
            console.log("No Clinical Site Managers found!");
        });
    }

    const isOtherStudyCoordinatorDataEmpty = (coordinator) => {
        let isDataEmpty = (coordinator.first_name === "") || (coordinator.last_name === "") ||
                        (coordinator.email === "") || (coordinator.phone_number === "");
        return isDataEmpty;
    }

    useEffect(() => {
        if (pathname.includes("view")) {
            setViewMode(true);
        } else {
            setViewMode(false);
        }
    }, [pathname]);

    // Loading wheel for edit/view
    useEffect(() => {
        if (window.onload || isAddMode) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [window]);

    useEffect(() => {
        // Load options for clinical site managers
        loadClinicalSiteManagers();
        loadClinicalProviders();

        if (isAddMode) {
            // Set USA as default country
            formik.setFieldValue("site_info.address.country", "USA");
        }
        else {
            // get facility and related providers by id
            facilityService.getSitesProvidersById(id)
            .then(facilityResponse => {
                const facilityData = facilityResponse.data;
                // Set facility specific data
                formik.setFieldValue("site_info.facility_name", facilityData.name);
                formik.setFieldValue("site_info.address.street_address_1", facilityData.address_line_1);
                formik.setFieldValue("site_info.address.street_address_2", facilityData.address_line_2);
                formik.setFieldValue("site_info.address.city", facilityData.city);
                formik.setFieldValue("site_info.address.state", facilityData.state);
                formik.setFieldValue("site_info.address.postal_code", facilityData.zipcode);
                formik.setFieldValue("site_info.address.country", facilityData.country);
                formik.setFieldValue("site_info.external_facility_id", facilityData.external_id);
                formik.setFieldValue("site_info.po_number", facilityData.po_number);
                formik.setFieldValue("site_info.use_po_in_pdr", facilityData.use_po_in_pdr);
                setUsePOinPDR(facilityData.use_po_in_pdr);

                formik.setFieldValue("site_info.alt_recipient_first_name", facilityData.alt_recipient_first_name);
                formik.setFieldValue("site_info.alt_recipient_last_name", facilityData.alt_recipient_last_name);
                if(facilityData.alt_recipient_phone_number){
                    const alt_phone_number = facilityData.alt_recipient_phone_number.slice(0,3)+"-"+facilityData.alt_recipient_phone_number.slice(3,6)+"-"+facilityData.alt_recipient_phone_number.slice(6);
                    formik.setFieldValue("site_info.alt_recipient_phone_number", alt_phone_number)
                }
                if(facilityData.alt_recipient_first_name){
                    formik.setFieldValue("site_info.alt_recipient_first_name", facilityData.alt_recipient_first_name);
                }else{
                    formik.setFieldValue("site_info.alt_recipient_first_name", "");
                }
                if(facilityData.alt_recipient_last_name){
                    formik.setFieldValue("site_info.alt_recipient_last_name", facilityData.alt_recipient_last_name);
                }else{
                    formik.setFieldValue("site_info.alt_recipient_last_name", "");
                }
                if(facilityData.alt_recipient_phone){
                    const alt_phone_number = facilityData.alt_recipient_phone.slice(0,3)+"-"+facilityData.alt_recipient_phone.slice(3,6)+"-"+facilityData.alt_recipient_phone.slice(6);
                    formik.setFieldValue("site_info.alt_recipient_phone", alt_phone_number)
                }else{
                    formik.setFieldValue('site_info.alt_recipient_phone', "")
                }
                if(facilityData.alt_recipient_email){
                    formik.setFieldValue("site_info.alt_recipient_email",facilityData.alt_recipient_email)
                }else{
                    formik.setFieldValue("site_info.alt_recipient_email","")
                }
                formik.setFieldValue("site_info.po_number", facilityData.po_number);
                formik.setFieldValue("site_info.use_po_in_pdr", facilityData.use_po_in_pdr);
                setUsePOinPDR(facilityData.use_po_in_pdr);

                if (facilityData.all_day_phone !== null){
                    facilityData.all_day_phone = facilityData.all_day_phone.slice(0,3)+"-"+facilityData.all_day_phone.slice(3,6)+"-"+facilityData.all_day_phone.slice(6);
                } else {
                    facilityData.all_day_phone = ""
                }

                formik.setFieldValue("site_info.all_day_phone", facilityData.all_day_phone);

                // Set clinical site manager data
                formik.setFieldValue("site_info.primary_contact_id", facilityData.site_manager?.id);
                setFacility(facilityData.id);

                // Set site investigator data
                formik.setFieldValue("site_investigator.first_name", facilityData.site_investigator?.first_name);
                formik.setFieldValue("site_investigator.last_name", facilityData.site_investigator?.last_name);
                formik.setFieldValue("site_investigator.email", facilityData.site_investigator?.email);
                if (facilityData.site_investigator) {
                    facilityData.site_investigator.phone = facilityData.site_investigator.phone.slice(0,3)+"-"+facilityData.site_investigator.phone.slice(3,6)+"-"+facilityData.site_investigator.phone.slice(6);
                }
                formik.setFieldValue("site_investigator.phone_number", facilityData.site_investigator?.phone);
                formik.setFieldValue("site_investigator.external_user_id", facilityData.site_investigator?.external_id);
                setSiteInvestigator(facilityData.site_investigator?.id);
                
                // Set study coordinator data
                if (facilityData.study_coordinator) {
                    setNumStudyCoordinators(facilityData.study_coordinator.length);

                    if (facilityData.study_coordinator.length > 0) {
                        let firstOtherCoordinatorSet = false;
                        let secondOtherCoordinatorSet = false;
                        let thirdOtherCoordinatorSet = false;
                        let fourthOtherCoordinatorSet = false;
                        for (let i = 0; i < facilityData.study_coordinator.length; i++) {
                            if (facilityData.study_coordinator[i].is_primary) {
                                // Set primary study coordinator data
                                formik.setFieldValue("primary_study_coordinator.first_name", facilityData.study_coordinator[i]?.first_name);
                                formik.setFieldValue("primary_study_coordinator.last_name", facilityData.study_coordinator[i]?.last_name);
                                formik.setFieldValue("primary_study_coordinator.email", facilityData.study_coordinator[i]?.email);
                                if (facilityData.study_coordinator[i]) {
                                    facilityData.study_coordinator[i].phone = facilityData.study_coordinator[i].phone.slice(0,3)+"-"+facilityData.study_coordinator[i].phone.slice(3,6)+"-"+facilityData.study_coordinator[i].phone.slice(6);
                                }
                                formik.setFieldValue("primary_study_coordinator.phone_number", facilityData.study_coordinator[i]?.phone);
                                setPrimaryStudyCoordinator(facilityData.study_coordinator[i]?.id);
                                firstOtherCoordinatorSet = true;
                            }
                            else if (firstOtherCoordinatorSet) {
                                // Set other study coordinator 1 data
                                formik.setFieldValue("other_study_coordinator_1.first_name", facilityData.study_coordinator[i]?.first_name);
                                formik.setFieldValue("other_study_coordinator_1.last_name", facilityData.study_coordinator[i]?.last_name);
                                formik.setFieldValue("other_study_coordinator_1.email", facilityData.study_coordinator[i]?.email);
                                if (facilityData.study_coordinator[i]) {
                                    facilityData.study_coordinator[i].phone = facilityData.study_coordinator[i].phone.slice(0,3)+"-"+facilityData.study_coordinator[i].phone.slice(3,6)+"-"+facilityData.study_coordinator[i].phone.slice(6);
                                }
                                formik.setFieldValue("other_study_coordinator_1.phone_number", facilityData.study_coordinator[i]?.phone);
                                setOtherStudyCoordinator1(facilityData.study_coordinator[i]?.id);
                                firstOtherCoordinatorSet =false;
                                secondOtherCoordinatorSet = true;
                            }
                            else if (secondOtherCoordinatorSet) {
                                // Set other study coordinator 2 data
                                formik.setFieldValue("other_study_coordinator_2.first_name", facilityData.study_coordinator[i]?.first_name);
                                formik.setFieldValue("other_study_coordinator_2.last_name", facilityData.study_coordinator[i]?.last_name);
                                formik.setFieldValue("other_study_coordinator_2.email", facilityData.study_coordinator[i]?.email);
                                if (facilityData.study_coordinator[i]) {
                                    facilityData.study_coordinator[i].phone = facilityData.study_coordinator[i].phone.slice(0,3)+"-"+facilityData.study_coordinator[i].phone.slice(3,6)+"-"+facilityData.study_coordinator[i].phone.slice(6);
                                }
                                formik.setFieldValue("other_study_coordinator_2.phone_number", facilityData.study_coordinator[i]?.phone);
                                setOtherStudyCoordinator2(facilityData.study_coordinator[i]?.id);
                                secondOtherCoordinatorSet = false;
                                thirdOtherCoordinatorSet = true;
                            }
                            else if (thirdOtherCoordinatorSet) {
                                // Set other study coordinator 3 data
                                formik.setFieldValue("other_study_coordinator_3.first_name", facilityData.study_coordinator[i]?.first_name);
                                formik.setFieldValue("other_study_coordinator_3.last_name", facilityData.study_coordinator[i]?.last_name);
                                formik.setFieldValue("other_study_coordinator_3.email", facilityData.study_coordinator[i]?.email);
                                if (facilityData.study_coordinator[i]) {
                                    facilityData.study_coordinator[i].phone = facilityData.study_coordinator[i].phone.slice(0,3)+"-"+facilityData.study_coordinator[i].phone.slice(3,6)+"-"+facilityData.study_coordinator[i].phone.slice(6);
                                }
                                formik.setFieldValue("other_study_coordinator_3.phone_number", facilityData.study_coordinator[i]?.phone);
                                setOtherStudyCoordinator3(facilityData.study_coordinator[i]?.id);
                                thirdOtherCoordinatorSet = false;
                                fourthOtherCoordinatorSet = true;
                            }
                            else {
                                // Set other study coordinator 4 data
                                formik.setFieldValue("other_study_coordinator_4.first_name", facilityData.study_coordinator[i]?.first_name);
                                formik.setFieldValue("other_study_coordinator_4.last_name", facilityData.study_coordinator[i]?.last_name);
                                formik.setFieldValue("other_study_coordinator_4.email", facilityData.study_coordinator[i]?.email);
                                if (facilityData.study_coordinator[i]) {
                                    facilityData.study_coordinator[i].phone = facilityData.study_coordinator[i].phone.slice(0,3)+"-"+facilityData.study_coordinator[i].phone.slice(3,6)+"-"+facilityData.study_coordinator[i].phone.slice(6);
                                }
                                formik.setFieldValue("other_study_coordinator_4.phone_number", facilityData.study_coordinator[i]?.phone);
                                setOtherStudyCoordinator4(facilityData.study_coordinator[i]?.id);
                            }
                        }
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                alertService.error(err);
            })
        }
    }, []);


    return (
        <Form className="pl-5" loading={isLoading} onSubmit={formik.handleSubmit}>
            <div className="mt-4">
                <Header as='h3' dividing>Site Details</Header>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site Name"
                        placeholder="Site Name"
                        name="site_info.facility_name"
                        width={3}
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.facility_name}
                        value={formik.values.site_info?.facility_name}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site ID Number"
                        placeholder="3-4 characters"
                        name="site_info.external_facility_id"
                        width={2}
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.external_facility_id}
                        value={formik.values.site_info?.external_facility_id}
                    />
                            <Form.Input
                            fluid
                            readOnly={viewMode}
                            label="24-Hour Number"
                            placeholder="24-Hour Number"
                            name="site_info.all_day_phone"
                            onChange={formik.handleChange}
                            error={formik.errors.site_info?.all_day_phone}
                            value={formik.values.site_info?.all_day_phone}
                            width={2}
                        />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 1"
                        placeholder="Shipping Address Line 1"
                        name="site_info.address.street_address_1"
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.address?.street_address_1}
                        value={formik.values.site_info?.address?.street_address_1}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="City"
                        placeholder="City"
                        name="site_info.address.city"
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.address?.city}
                        value={formik.values.site_info?.address?.city}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="State"
                        placeholder="State"
                        name="site_info.address.state"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.site_info?.address?.state}
                        value={formik.values.site_info?.address?.state}
                        width={2}
                        options={States}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 2"
                        placeholder="(Apt, Bldg, Unit)"
                        name="site_info.address.street_address_2"
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.address?.street_address_2}
                        value={formik.values.site_info?.address?.street_address_2}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Zip Code"
                        placeholder="Zip Code"
                        name="site_info.address.postal_code"
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.address?.postal_code}
                        value={formik.values.site_info?.address?.postal_code}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Country"
                        placeholder="Country"
                        name="site_info.address.country"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.site_info?.address?.country}
                        value={formik.values.site_info?.address?.country}
                        width={2}
                        options={Countries}
                    />
                </Form.Group>
                <Form.Group
                    style={{display: 'flex', alignItems: 'self-end'}}
                    >
                    <Form.Input
                        key="PO-NUM"
                        fluid
                        readOnly={viewMode}
                        label="PO Number"
                        placeholder="PO Number"
                        name="site_info.po_number"
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.po_number}
                        value={formik.values.site_info?.po_number}
                        width={2}
                    />
                    <Checkbox toggle
                        style={{marginLeft: '15px'}}
                        disabled={viewMode}
                        checked={usePOinPDR}
                        label='Use PO number in PDR'
                        onChange = { () => setUsePOinPDR(!usePOinPDR)}
                        />
                </Form.Group>
            </div>
            <div className="pt-5">
            <Header as='h3' dividing>Site Manager</Header>
            <Form.Group>
                <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Clinical Site Manager Name"
                        placeholder="Site Manager Name"
                        name="site_info.primary_contact_id"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.site_info?.primary_contact_id}
                        value={formik.values.site_info?.primary_contact_id}
                        width={4}
                        options={clinicalSiteManagers ? clinicalSiteManagers : []}
                    />
                </Form.Group>
            </div>
            <div className="pt-5">
                <Header as='h3' dividing>Site Investigator</Header>
                <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={useNewSiteInvestigator}
                        label="Clinical Site Investigator Name"
                        placeholder="Site Investigator Name"
                        name={"site_investigator.id"}
                        onChange={(event, data) => handleProviderOptionChange(data.value, data.options, data.name)}
                        error={formik.values.site_investigator?.id}
                        value={formik.values.site_investigator?.id}
                        width={4}
                        options={clinicalSiteProviders ? clinicalSiteProviders : []}
                    />
                    <Form.Field>
                        <Checkbox toggle
                        disabled={viewMode}
                        label='Use Exisiting Site Investigator'
                        onChange = { () => setUseNewSiteInvestigator(!useNewSiteInvestigator)}
                        // onClick = { () => handleUseExistingProvider(formik.values.site_investigator)}
                        />
                    </Form.Field>

            </div>
            <div className="pt-5">
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site Investigator First Name"
                        placeholder="First Name"
                        name="site_investigator.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.site_investigator?.first_name}
                        value={formik.values.site_investigator?.first_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site Investigator Last Name"
                        placeholder="Last Name"
                        name="site_investigator.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.site_investigator?.last_name}
                        value={formik.values.site_investigator?.last_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="NPI"
                        placeholder="NPI (10 digits)"
                        name="site_investigator.external_user_id"
                        onChange={formik.handleChange}
                        error={formik.errors.site_investigator?.external_user_id}
                        value={formik.values.site_investigator?.external_user_id}
                        width={2}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site Investigator Email"
                        placeholder="Email"
                        name="site_investigator.email"
                        onChange={formik.handleChange}
                        error={formik.errors.site_investigator?.email}
                        value={formik.values.site_investigator?.email}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Site Investigator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="site_investigator.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.site_investigator?.phone_number}
                        value={formik.values.site_investigator?.phone_number}
                        width={3}
                    />
                </Form.Group>
            </div>
            <div className="pt-5">
                <Header as='h3' dividing>Primary Study Coordinator</Header>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Primary Study Coordinator First Name"
                        placeholder="First Name"
                        name="primary_study_coordinator.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.primary_study_coordinator?.first_name}
                        value={formik.values.primary_study_coordinator?.first_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Primary Study Coordinator Last Name"
                        placeholder="Last Name"
                        name="primary_study_coordinator.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.primary_study_coordinator?.last_name}
                        value={formik.values.primary_study_coordinator?.last_name}
                        width={3}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Primary Study Coordinator Email"
                        placeholder="Email"
                        name="primary_study_coordinator.email"
                        onChange={formik.handleChange}
                        error={formik.errors.primary_study_coordinator?.email}
                        value={formik.values.primary_study_coordinator?.email}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Primary Study Coordinator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="primary_study_coordinator.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.primary_study_coordinator?.phone_number}
                        value={formik.values.primary_study_coordinator?.phone_number}
                        width={3}
                    />
                </Form.Group>
                <Header as='h3' dividing>Other Study Coordinators</Header>
                <Grid columns={2} divided>
                <Grid.Row >
                <Grid.Column class="d-flex justify-content-between">
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator First Name"
                        placeholder="First Name"
                        name="other_study_coordinator_1.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_1?.first_name}
                        value={formik.values.other_study_coordinator_1?.first_name}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Last Name"
                        placeholder="Last Name"
                        name="other_study_coordinator_1.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_1?.last_name}
                        value={formik.values.other_study_coordinator_1?.last_name}
                        width={6}
                    />
                </Form.Group>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Email"
                        placeholder="Email"
                        name="other_study_coordinator_1.email"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_1?.email}
                        value={formik.values.other_study_coordinator_1?.email}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="other_study_coordinator_1.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_1?.phone_number}
                        value={formik.values.other_study_coordinator_1?.phone_number}
                        width={6}
                    />
                </Form.Group>
                </Grid.Column>
                <Grid.Column>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator First Name"
                        placeholder="First Name"
                        name="other_study_coordinator_2.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_2?.first_name}
                        value={formik.values.other_study_coordinator_2?.first_name}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Last Name"
                        placeholder="Last Name"
                        name="other_study_coordinator_2.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_2?.last_name}
                        value={formik.values.other_study_coordinator_2?.last_name}
                        width={6}
                    />
                </Form.Group>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Email"
                        placeholder="Email"
                        name="other_study_coordinator_2.email"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_2?.email}
                        value={formik.values.other_study_coordinator_2?.email}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="other_study_coordinator_2.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_2?.phone_number}
                        value={formik.values.other_study_coordinator_2?.phone_number}
                        width={6}
                    />
                </Form.Group>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator First Name"
                        placeholder="First Name"
                        name="other_study_coordinator_3.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_3?.first_name}
                        value={formik.values.other_study_coordinator_3?.first_name}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Last Name"
                        placeholder="Last Name"
                        name="other_study_coordinator_3.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_3?.last_name}
                        value={formik.values.other_study_coordinator_3?.last_name}
                        width={6}
                    />
                </Form.Group>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Email"
                        placeholder="Email"
                        name="other_study_coordinator_3.email"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_3?.email}
                        value={formik.values.other_study_coordinator_3?.email}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="other_study_coordinator_3.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_3?.phone_number}
                        value={formik.values.other_study_coordinator_3?.phone_number}
                        width={6}
                    />
                </Form.Group>
                </Grid.Column>
                <Grid.Column >
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator First Name"
                        placeholder="First Name"
                        name="other_study_coordinator_4.first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_4?.first_name}
                        value={formik.values.other_study_coordinator_4?.first_name}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Last Name"
                        placeholder="Last Name"
                        name="other_study_coordinator_4.last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_4?.last_name}
                        value={formik.values.other_study_coordinator_4?.last_name}
                        width={6}
                    />
                </Form.Group>
                <Form.Group style={{justifyContent:'space-around'}}>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Email"
                        placeholder="Email"
                        name="other_study_coordinator_4.email"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_4?.email}
                        value={formik.values.other_study_coordinator_4?.email}
                        width={6}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Other Study Coordinator Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="other_study_coordinator_4.phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.other_study_coordinator_4?.phone_number}
                        value={formik.values.other_study_coordinator_4?.phone_number}
                        width={6}
                    />
                </Form.Group>
                </Grid.Column>
                </Grid.Row>
                </Grid>
            </div>
            <div className="pt-5">
                <Header as='h3' dividing>PDR Alternate Recipient</Header>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Alternate Recipient First Name"
                        placeholder="First Name"
                        name='site_info.alt_recipient_first_name'
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.alt_recipient_first_name}
                        value={formik.values.site_info?.alt_recipient_first_name}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Alternate Recipient Last Name"
                        placeholder="Last Name"
                        name='site_info.alt_recipient_last_name'
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.alt_recipient_last_name}
                        value={formik.values.site_info?.alt_recipient_last_name}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Alternate Recipient Phone Number"
                        placeholder="xxx-xxx-xxxx"
                        name='site_info.alt_recipient_phone'
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.alt_recipient_phone}
                        value={formik.values.site_info?.alt_recipient_phone}
                        width={4}
                    />
                     <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Alternate Recipient Email"
                        placeholder="Email"
                        name='site_info.alt_recipient_email'
                        onChange={formik.handleChange}
                        error={formik.errors.site_info?.alt_recipient_email}
                        value={formik.values.site_info?.alt_recipient_email}
                        width={4}
                    />
                </Form.Group>
            </div>
            <div className="pt-5 mt-5 pl-2">
                <Form.Group>
                    <div className="pr-3">
                        <Button 
                            type="submit"
                            primary
                            onSubmit={formik.handleSubmit}
                            disabled={formik.isSubmitting || viewMode}  
                        >
                            {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Submit
                        </Button>
                    </div>
                    <Button secondary as={ Link } name='facilities' to='/facilities'>Cancel</Button>
                </Form.Group>
            </div>
        </Form>
    );
}

export { AddEdit };