export const Countries = [
    {
        "text": "USA",
        "key": "USA",
        "value": "USA",
    }
];

export const States = [
    {
        "text": "Alabama",
        "key": "AL",
        "value": "AL",
    },
    {
        "text": "Alaska",
        "key": "AK",
        "value": "AK",
    },
    {
        "text": "American Samoa",
        "key": "AS",
        "value": "AS",
    },
    {
        "text": "Arizona",
        "key": "AZ",
        "value": "AZ",
    },
    {
        "text": "Arkansas",
        "key": "AR",
        "value": "AR",
    },
    {
        "text": "California",
        "key": "CA",
        "value": "CA",
    },
    {
        "text": "Colorado",
        "key": "CO",
        "value": "CO",
    },
    {
        "text": "Connecticut",
        "key": "CT",
        "value": "CT",
    },
    {
        "text": "Delaware",
        "key": "DE",
        "value": "DE",
    },
    {
        "text": "District Of Columbia",
        "key": "DC",
        "value": "DC",
    },
    {
        "text": "Federated States Of Micronesia",
        "key": "FM",
        "value": "FM",
    },
    {
        "text": "Florida",
        "key": "FL",
        "value": "FL",
    },
    {
        "text": "Georgia",
        "key": "GA",
        "value": "GA",
    },
    {
        "text": "Guam Gu",
        "key": "GU",
        "value": "GU",
    },
    {
        "text": "Hawaii",
        "key": "HI",
        "value": "HI",
    },
    {
        "text": "Idaho",
        "key": "ID",
        "value": "ID",
    },
    {
        "text": "Illinois",
        "key": "IL",
        "value": "IL",
    },
    {
        "text": "Indiana",
        "key": "IN",
        "value": "IN",
    },
    {
        "text": "Iowa",
        "key": "IA",
        "value": "IA",
    },
    {
        "text": "Kansas",
        "key": "KS",
        "value": "KS",
    },
    {
        "text": "Kentucky",
        "key": "KY",
        "value": "KY",
    },
    {
        "text": "Louisiana",
        "key": "LA",
        "value": "LA",
    },
    {
        "text": "Maine",
        "key": "ME",
        "value": "ME",
    },
    {
        "text": "Marshall Islands",
        "key": "MH",
        "value": "MH",
    },
    {
        "text": "Maryland",
        "key": "MD",
        "value": "MD",
    },
    {
        "text": "Massachusetts",
        "key": "MA",
        "value": "MA",
    },
    {
        "text": "Michigan",
        "key": "MI",
        "value": "MI",
    },
    {
        "text": "Minnesota",
        "key": "MN",
        "value": "MN",
    },
    {
        "text": "Mississippi",
        "key": "MS",
        "value": "MS",
    },
    {
        "text": "Missouri",
        "key": "MO",
        "value": "MO",
    },
    {
        "text": "Montana",
        "key": "MT",
        "value": "MT",
    },
    {
        "text": "Nebraska",
        "key": "NE",
        "value": "NE",
    },
    {
        "text": "Nevada",
        "key": "NV",
        "value": "NV",
    },
    {
        "text": "New Hampshire",
        "key": "NH",
        "value": "NH",
    },
    {
        "text": "New Jersey",
        "key": "NJ",
        "value": "NJ",
    },
    {
        "text": "New Mexico",
        "key": "NM",
        "value": "NM",
    },
    {
        "text": "New York",
        "key": "NY",
        "value": "NY",
    },
    {
        "text": "North Carolina",
        "key": "NC",
        "value": "NC",
    },
    {
        "text": "North Dakota",
        "key": "ND",
        "value": "ND",
    },
    {
        "text": "Northern Mariana Islands",
        "key": "MP",
        "value": "MP",
    },
    {
        "text": "Ohio",
        "key": "OH",
        "value": "OH",
    },
    {
        "text": "Oklahoma",
        "key": "OK",
        "value": "OK",
    },
    {
        "text": "Oregon",
        "key": "OR",
        "value": "OR",
    },
    {
        "text": "Palau",
        "key": "PW",
        "value": "PW",
    },
    {
        "text": "Pennsylvania",
        "key": "PA",
        "value": "PA",
    },
    {
        "text": "Puerto Rico",
        "key": "PR",
        "value": "PR",
    },
    {
        "text": "Rhode Island",
        "key": "RI",
        "value": "RI",
    },
    {
        "text": "South Carolina",
        "key": "SC",
        "value": "SC",
    },
    {
        "text": "South Dakota",
        "key": "SD",
        "value": "SD",
    },
    {
        "text": "Tennessee",
        "key": "TN",
        "value": "TN",
    },
    {
        "text": "Texas",
        "key": "TX",
        "value": "TX",
    },
    {
        "text": "Utah",
        "key": "UT",
        "value": "UT",
    },
    {
        "text": "Vermont",
        "key": "VT",
        "value": "VT",
    },
    {
        "text": "Virgin Islands",
        "key": "VI",
        "value": "VI",
    },
    {
        "text": "Virginia",
        "key": "VA",
        "value": "VA",
    },
    {
        "text": "Washington",
        "key": "WA",
        "value": "WA",
    },
    {
        "text": "West Virginia",
        "key": "WV",
        "value": "WV",
    },
    {
        "text": "Wisconsin",
        "key": "WI",
        "value": "WI",
    },
    {
        "text": "Wyoming",
        "key": "WY",
        "value": "WY",
    }
]

export const booleanOptions = [
    {
        key: "yes",
        text: "Yes",
        value: true
    },
    {
        key: "no",
        text: "No",
        value: false
    },
]

export const genderOptions = [
    {
        key: "Male",
        text: "Male",
        value: "Male"
    }, 
    {
        key: "Female",
        text: "Female",
        value: "Female"
    }, 
    {
        key: "Non-binary",
        text: "Non-binary",
        value: "Non-binary"
    }
]

export const sexOptions = [
    {
        key: "Male",
        text: "Male",
        value: "Male"
    },
    {
        key: "Female",
        text: "Female",
        value: "Female"
    }
]

export const iphoneOptions = [
    {
        key: "6s",
        text: "iPhone 6S",
        value: "6s"
    },
    {
        key: "6sPlus",
        text: "iPhone 6S Plus",
        value: "6sPlus"
    },
    {
        key: "SE",
        text: "iPhone SE",
        value: "SE"
    },
    {
        key: "7",
        text: "iPhone 7",
        value: "7"
    },
    {
        key: "7Plus",
        text: "iPhone 7 Plus",
        value: "7Plus"
    },
    {
        key: "8",
        text: "iPhone 8",
        value: "8"
    },
    {
        key: "8Plus",
        text: "iPhone 8 Plus",
        value: "8Plus"
    },
    {
        key: "X",
        text: "iPhone X",
        value: "X"
    },
    {
        key: "XR",
        text: "iPhone XR",
        value: "XR"
    },
    {
        key: "XS",
        text: "iPhone XS",
        value: "XS"
    },
    {
        key: "XSMax",
        text: "iPhone XS Max",
        value: "XSMax"
    },
    {
        key: "11",
        text: "iPhone 11",
        value: "11"
    },
    {
        key: "11Pro",
        text: "iPhone 11 Pro",
        value: "11Pro"
    },
    {
        key: "11ProMax",
        text: "iPhone 11 Pro Max",
        value: "11ProMax"
    },
    {
        key: "SE2ndGen",
        text: "iPhone SE 2nd Gen",
        value: "SE2ndGen"
    },
    {
        key: "12",
        text: "iPhone 12",
        value: "12"
    },
    {
        key: "12Mini",
        text: "iPhone 12 Mini",
        value: "12Mini"
    },
    {
        key: "12Pro",
        text: "iPhone 12 Pro",
        value: "12Pro"
    },
    {
        key: "12ProMax",
        text: "iPhone 12 Pro Max",
        value: "12ProMax"
    },
    {
        key: "13",
        text: "iPhone 13",
        value: "13"
    },
    {
        key: "13Mini",
        text: "iPhone 13 Mini",
        value: "13Mini"
    },
    {
        key: "13Pro",
        text: "iPhone 13 Pro",
        value: "13Pro"
    },
    {
        key: "13ProMax",
        text: "iPhone 13 Pro Max",
        value: "13ProMax"
    },
    {
        key: "14",
        text: "iPhone 14",
        value: "14"
    },
    {
        key: "14Mini",
        text: "iPhone 14 Mini",
        value: "14Mini"
    },
    {
        key: "14Pro",
        text: "iPhone 14 Pro",
        value: "14Pro"
    },
    {
        key: "14ProMax",
        text: "iPhone 14 Pro Max",
        value: "14ProMax"
    },
]

export const iosOptions = [
    {
        key: "13.0",
        text: "iOS 13.0",
        value: "13.0"
    },
    {
        key: "13.1",
        text: "iOS 13.1",
        value: "13.1"
    },
    {
        key: "13.2",
        text: "iOS 13.2",
        value: "13.2"
    },
    {
        key: "13.3",
        text: "iOS 13.3",
        value: "13.3"
    },
    {
        key: "13.4",
        text: "iOS 13.4",
        value: "13.4"
    },
    {
        key: "13.5",
        text: "iOS 13.5",
        value: "13.5"
    },
    {
        key: "13.6",
        text: "iOS 13.6",
        value: "13.6"
    },
    {
        key: "13.7",
        text: "iOS 13.7",
        value: "13.7"
    },
    {
        key: "14.0",
        text: "iOS 14.0",
        value: "14.0"
    },
    {
        key: "14.1",
        text: "iOS 14.1",
        value: "14.1"
    },
    {
        key: "14.2",
        text: "iOS 14.2",
        value: "14.2"
    },
    {
        key: "14.3",
        text: "iOS 14.3",
        value: "14.3"
    },
    {
        key: "14.4",
        text: "iOS 14.4",
        value: "14.4"
    },
    {
        key: "14.5",
        text: "iOS 14.5",
        value: "14.5"
    },
    {
        key: "14.6",
        text: "iOS 14.6",
        value: "14.6"
    },
    {
        key: "14.7",
        text: "iOS 14.7",
        value: "14.7"
    },
    {
        key: "14.8",
        text: "iOS 14.8",
        value: "14.8"
    },
    {
        key: "15.0",
        text: "iOS 15.0",
        value: "15.0"
    },
    {
        key: "15.1",
        text: "iOS 15.1",
        value: "15.1"
    },
    {
        key: "15.2",
        text: "iOS 15.2",
        value: "15.2"
    },
    {
        key: "15.6",
        text: "iOS 15.6",
        value: "15.6"
    },
    {
        key: "16.0",
        text: "iOS 16.0",
        value: "16.0"
    },
    {
        key: "16.1",
        text: "iOS 16.1",
        value: "16.1"
    },
    {
        key: "16.2",
        text: "iOS 16.2",
        value: "16.2"
    },
    {
        key: "16.3",
        text: "iOS 16.3",
        value: "16.3"
    },
    {
        key: "16.4",
        text: "iOS 16.4",
        value: "16.4"
    },
    {
        key: "16.5",
        text: "iOS 16.5",
        value: "16.5"
    },
    {
        key: "16.6",
        text: "iOS 16.6",
        value: "16.6"
    },
    {
        key: "16.7",
        text: "iOS 16.7",
        value: "16.7"
    },
    {
        key: "17.0",
        text: "iOS 17.0",
        value: "17.0"
    },
    {
        key: "17.1",
        text: "iOS 17.1",
        value: "17.1"
    },
    {
        key: "17.2",
        text: "iOS 17.2",
        value: "17.2"
    },
    {
        key: "17.3",
        text: "iOS 17.3",
        value: "17.3"
    },
    {
        key: "17.4",
        text: "iOS 17.4",
        value: "17.4"
    }
]

export const lowerPatchBackSettingOptions = [
    {
        key: "8.25",
        text: "8.25",
        value: "8.25"
    },
    {
        key: "8.5",
        text: "8.5",
        value: "8.5"
    },
    {
        key: "8.75",
        text: "8.75",
        value: "8.75"
    },
    {
        key: "9.0",
        text: "9.0",
        value: "9.0"
    },
    {
        key: "9.25",
        text: "9.25",
        value: "9.25"
    },
    {
        key: "9.5",
        text: "9.5",
        value: "9.5"
    },
    {
        key: "9.75",
        text: "9.75",
        value: "9.75"
    },
    {
        key: "10.0",
        text: "10.0",
        value: "10.0"
    },
    {
        key: "10.25",
        text: "10.25",
        value: "10.25"
    },
    {
        key: "10.5",
        text: "10.5",
        value: "10.5"
    },
    {
        key: "10.75",
        text: "10.75",
        value: "10.75"
    },
    {
        key: "11.0",
        text: "11.0",
        value: "11.0"
    },
    {
        key: "11.25",
        text: "11.25",
        value: "11.25"
    },
    {
        key: "11.5",
        text: "11.5",
        value: "11.5"
    },
    {
        key: "11.75",
        text: "11.75",
        value: "11.75"
    },
    {
        key: "12.0",
        text: "12.0",
        value: "12.0"
    },
    {
        key: "12.25",
        text: "12.25",
        value: "12.25"
    },
    {
        key: "12.5",
        text: "12.5",
        value: "12.5"
    },
    {
        key: "12.75",
        text: "12.75",
        value: "12.75"
    },
    {
        key: "13.0",
        text: "13.0",
        value: "13.0"
    },
    {
        key: "13.25",
        text: "13.25",
        value: "13.25"
    },
    {
        key: "13.5",
        text: "13.5",
        value: "13.5"
    },
    {
        key: "13.75",
        text: "13.75",
        value: "13.75"
    },
    {
        key: "14.0",
        text: "14.0",
        value: "14.0"
    },
    {
        key: "14.25",
        text: "14.25",
        value: "14.25"
    },
    {
        key: "14.5",
        text: "14.5",
        value: "14.5"
    },
    {
        key: "14.75",
        text: "14.75",
        value: "14.75"
    },
    {
        key: "15.0",
        text: "15.0",
        value: "15.0"
    },
    {
        key: "15.25",
        text: "15.25",
        value: "15.25"
    },
    {
        key: "15.5",
        text: "15.5",
        value: "15.5"
    },
    {
        key: "15.75",
        text: "15.75",
        value: "15.75"
    },
    {
        key: "16.0",
        text: "16.0",
        value: "16.0"
    },
    {
        key: "16.25",
        text: "16.25",
        value: "16.25"
    },
    {
        key: "16.5",
        text: "16.5",
        value: "16.5"
    },
    {
        key: "16.75",
        text: "16.75",
        value: "16.75"
    },
    {
        key: "17.0",
        text: "17.0",
        value: "17.0"
    },
    {
        key: "17.25",
        text: "17.25",
        value: "17.25"
    },
    {
        key: "17.5",
        text: "17.5",
        value: "17.5"
    },
    {
        key: "17.75",
        text: "17.75",
        value: "17.75"
    },
    {
        key: "18.0",
        text: "18.0",
        value: "18.0"
    },
    {
        key: "18.25",
        text: "18.25",
        value: "18.25"
    },
    {
        key: "18.5",
        text: "18.5",
        value: "18.5"
    },
    {
        key: "18.75",
        text: "18.75",
        value: "18.75"
    },
    {
        key: "19.0",
        text: "19.0",
        value: "19.0"
    },
    {
        key: "19.25",
        text: "19.25",
        value: "19.25"
    },
    {
        key: "19.5",
        text: "19.5",
        value: "19.5"
    },
    {
        key: "19.75",
        text: "19.75",
        value: "19.75"
    },
    {
        key: "20.0",
        text: "20.0",
        value: "20.0"
    },
    {
        key: "20.25",
        text: "20.25",
        value: "20.25"
    },
    {
        key: "20.5",
        text: "20.5",
        value: "20.5"
    },
    {
        key: "20.75",
        text: "20.75",
        value: "20.75"
    },
    {
        key: "21.0",
        text: "21.0",
        value: "21.0"
    },
    {
        key: "21.25",
        text: "21.25",
        value: "21.25"
    },
    {
        key: "21.5",
        text: "21.5",
        value: "21.5"
    },
    {
        key: "21.75",
        text: "21.75",
        value: "21.75"
    },
    {
        key: "22.0",
        text: "22.0",
        value: "22.0"
    },
    {
        key: "22.25",
        text: "22.25",
        value: "22.25"
    },
    {
        key: "22.5",
        text: "22.5",
        value: "22.5"
    },
    {
        key: "22.75",
        text: "22.75",
        value: "22.75"
    },
    {
        key: "23.0",
        text: "23.0",
        value: "23.0"
    },
    {
        key: "27.0",
        text: "27.0",
        value: "27.0"
    },
    {
        key: "27.5",
        text: "27.5",
        value: "27.5"
    },
    {
        key: "28.0",
        text: "28.0",
        value: "28.0"
    },
    {
        key: "28.5",
        text: "28.5",
        value: "28.5"
    },
    {
        key: "29.0",
        text: "29.0",
        value: "29.0"
    },
    {
        key: "29.5",
        text: "29.5",
        value: "29.5"
    },
    {
        key: "30.0",
        text: "30.0",
        value: "30.0"
    },
    {
        key: "30.5",
        text: "30.5",
        value: "30.5"
    },
    {
        key: "31.0",
        text: "31.0",
        value: "31.0"
    },
    {
        key: "31.5",
        text: "31.5",
        value: "31.5"
    },
    {
        key: "32.0",
        text: "32.0",
        value: "32.0"
    },
    {
        key: "32.5",
        text: "32.5",
        value: "32.5"
    },
    {
        key: "33.0",
        text: "33.0",
        value: "33.0"
    }
]

export const lowerPatchFrontSettingOptions = [
    {
        key: "8.5",
        text: "8.5",
        value: "8.5"
    },
    {
        key: "8.75",
        text: "8.75",
        value: "8.75"
    },
    {
        key: "9.0",
        text: "9.0",
        value: "9.0"
    },
    {
        key: "9.25",
        text: "9.25",
        value: "9.25"
    },
    {
        key: "9.5",
        text: "9.5",
        value: "9.5"
    },
    {
        key: "9.75",
        text: "9.75",
        value: "9.75"
    },
    {
        key: "10.0",
        text: "10.0",
        value: "10.0"
    },
    {
        key: "10.25",
        text: "10.25",
        value: "10.25"
    },
    {
        key: "10.5",
        text: "10.5",
        value: "10.5"
    },
    {
        key: "10.75",
        text: "10.75",
        value: "10.75"
    },
    {
        key: "11.0",
        text: "11.0",
        value: "11.0"
    },
    {
        key: "11.25",
        text: "11.25",
        value: "11.25"
    },
    {
        key: "11.5",
        text: "11.5",
        value: "11.5"
    },
    {
        key: "11.75",
        text: "11.75",
        value: "11.75"
    },
    {
        key: "12.0",
        text: "12.0",
        value: "12.0"
    },
    {
        key: "12.25",
        text: "12.25",
        value: "12.25"
    },
    {
        key: "12.5",
        text: "12.5",
        value: "12.5"
    },
    {
        key: "12.75",
        text: "12.75",
        value: "12.75"
    },
    {
        key: "13.0",
        text: "13.0",
        value: "13.0"
    },
    {
        key: "13.25",
        text: "13.25",
        value: "13.25"
    },
    {
        key: "13.5",
        text: "13.5",
        value: "13.5"
    },
    {
        key: "13.75",
        text: "13.75",
        value: "13.75"
    },
    {
        key: "14.0",
        text: "14.0",
        value: "14.0"
    },
    {
        key: "14.25",
        text: "14.25",
        value: "14.25"
    },
    {
        key: "14.5",
        text: "14.5",
        value: "14.5"
    },
    {
        key: "14.75",
        text: "14.75",
        value: "14.75"
    },
    {
        key: "15.0",
        text: "15.0",
        value: "15.0"
    },
    {
        key: "15.25",
        text: "15.25",
        value: "15.25"
    },
    {
        key: "15.5",
        text: "15.5",
        value: "15.5"
    },
    {
        key: "15.75",
        text: "15.75",
        value: "15.75"
    },
    {
        key: "16.0",
        text: "16.0",
        value: "16.0"
    },
    {
        key: "16.25",
        text: "16.25",
        value: "16.25"
    },
    {
        key: "16.5",
        text: "16.5",
        value: "16.5"
    },
    {
        key: "16.75",
        text: "16.75",
        value: "16.75"
    },
    {
        key: "17.0",
        text: "17.0",
        value: "17.0"
    },
    {
        key: "17.25",
        text: "17.25",
        value: "17.25"
    },
    {
        key: "17.5",
        text: "17.5",
        value: "17.5"
    },
    {
        key: "17.75",
        text: "17.75",
        value: "17.75"
    },
    {
        key: "18.0",
        text: "18.0",
        value: "18.0"
    },
    {
        key: "18.25",
        text: "18.25",
        value: "18.25"
    },
    {
        key: "18.5",
        text: "18.5",
        value: "18.5"
    },
    {
        key: "18.75",
        text: "18.75",
        value: "18.75"
    },
    {
        key: "19.0",
        text: "19.0",
        value: "19.0"
    },
    {
        key: "19.25",
        text: "19.25",
        value: "19.25"
    },
    {
        key: "19.5",
        text: "19.5",
        value: "19.5"
    }
]

export const upperPatchPadSettingOptions = [
    {
        key: "12",
        text: "12",
        value: "12"
    },
    {
        key: "13",
        text: "13",
        value: "13"
    },
    {
        key: "14",
        text: "14",
        value: "14"
    },
    {
        key: "15",
        text: "15",
        value: "15"
    },
    {
        key: "16",
        text: "16",
        value: "16"
    },
    {
        key: "17",
        text: "17",
        value: "17"
    },
    {
        key: "18",
        text: "18",
        value: "18"
    },
    {
        key: "19",
        text: "19",
        value: "19"
    },
    {
        key: "20",
        text: "20",
        value: "20"
    }
]

export const frontShoulderStrapSettingOptions = [
    {
        key: "1",
        text: "1",
        value: "1"
    },
    {
        key: "2",
        text: "2",
        value: "2"
    },
    {
        key: "3",
        text: "3",
        value: "3"
    },
    {
        key: "4",
        text: "4",
        value: "4"
    },
    {
        key: "5",
        text: "5",
        value: "5"
    },
    {
        key: "6",
        text: "6",
        value: "6"
    },
    {
        key: "7",
        text: "7",
        value: "7"
    },
    {
        key: "8",
        text: "8",
        value: "8"
    },
    {
        key: "9",
        text: "9",
        value: "9"
    },
    {
        key: "10",
        text: "10",
        value: "10"
    },
    {
        key: "11",
        text: "11",
        value: "11"
    }
]

export const backShoulderStrapSettingOptions = [
    {
        key: "A",
        text: "A",
        value: "A"
    },
    {
        key: "B",
        text: "B",
        value: "B"
    },
    {
        key: "C",
        text: "C",
        value: "C"
    },
    {
        key: "D",
        text: "D",
        value: "D"
    },
    {
        key: "E",
        text: "E",
        value: "E"
    },
    {
        key: "F",
        text: "F",
        value: "F"
    },
    {
        key: "G",
        text: "G",
        value: "G"
    },
    {
        key: "H",
        text: "H",
        value: "H"
    },
    {
        key: "I",
        text: "I",
        value: "I"
    },
    {
        key: "J",
        text: "J",
        value: "J"
    },
    {
        key: "K",
        text: "K",
        value: "K"
    },
    {
        key: "L",
        text: "L",
        value: "L"
    },
    {
        key: "M",
        text: "M",
        value: "M"
    },
    {
        key: "N",
        text: "N",
        value: "N"
    },
    {
        key: "O",
        text: "O",
        value: "O"
    },
    {
        key: "P",
        text: "P",
        value: "P"
    },
    {
        key: "Q",
        text: "Q",
        value: "Q"
    }
]
export const esUserRoles = [
    {
        key: "CUSTOMER_SERVICE",
        text: "ES_CUSTOMER_SERVICE",
        value: "ES_CUSTOMER_SERVICE"
    },
    {
        key: "STUDY_MANAGER",
        text: "ES_CLINICAL_SITE_MANAGER",
        value: "ES_CLINICAL_SITE_MANAGER"
    }
]

export const providerRoleOptions = [
    {
        key: "prescribing",
        text: "Prescribing",
        value: "PRESCRIBING_PROVIDER"
    }, 
    {
        key: "outpatient",
        text: "Outpatient",
        value: "outpatient"
    }, 
    {
        key: "siteinvestigator",
        text: "Site Investigator",
        value: "siteinvestigator"
    },
    {
        key: "studycoordinator",
        text: "Study Coordinator",
        value: "studycoordinator"
    }
]

export const uspsBaseUrl = 'https://secure.shippingapis.com/ShippingAPI.dll?API=Verify&xml=';
